// import axios from "axios";
import { EstoqueType } from "../models/Estoque";
import { ENDPOINTS } from "../configs/apiConfigs"; // Ajuste o caminho conforme necessário
import axiosInstance from './axiosConfig'; // Ajuste o caminho conforme necessário

const axios = axiosInstance;

class EstoqueService {
    private API_URL = ENDPOINTS.estoque;

    // Método para obter todos os itens
    public async getAllItens(): Promise<EstoqueType[]> {
        try {
            const response = await axios.get(this.API_URL);
            console.log(response.data);
            
            return response.data;
        } catch (error) {
            throw new Error(`Erro ao obter itens de estoque: ${error}`);
        }
    }

    // Método para contar os itens
    public async countItens(): Promise<number> {
        try {
            const response = await axios.get(this.API_URL);
            console.log("estoque");
            console.log(response.data);
            
            return response.data.length;
        } catch (error) {
            throw new Error(`Erro ao obter itens de estoque: ${error}`);
        }
    }

    // Método para obter um item por ID
    public async getItemById(id: number): Promise<EstoqueType> {
        try {
            const response = await axios.get(`${this.API_URL}/${id}`);
            return response.data;
        } catch (error) {
            throw new Error(`Erro ao obter item: ${error}`);
        }
    }

    // Método para criar um novo item
    public async createItem(item: EstoqueType): Promise<EstoqueType> {
        try {
            const response = await axios.post(this.API_URL, item);
            return response.data;
        } catch (error) {
            throw new Error(`Erro ao criar item: ${error}`);
        }
    }

    // Método para atualizar um item
    public async updateItem(id: number, item: EstoqueType): Promise<EstoqueType> {
        try {
            const response = await axios.put(`${this.API_URL}/${id}`, item);
            return response.data;
        } catch (error) {
            throw new Error(`Erro ao atualizar item: ${error}`);
        }
    }

    // Método para deletar um item
    public async deleteItem(id: number): Promise<void> {
        try {
            await axios.delete(`${this.API_URL}/${id}`);
        } catch (error) {
            throw new Error(`Erro ao deletar item: ${error}`);
        }
    }
}

export default new EstoqueService();
