export type UserProfileToken = {
    name: string;
    email: string;
    token: string;
  };
  
export type UserProfile = {
name: string;
email: string;
role: string;
cd_entidade?: number;
diretoria?: number | string;
diretoria_descricao?: string;
};

export type UserType = {
  id?: number;
  name?: string;
  cpf_cnpj?: string;
  password?: string;
  email?: string;
  audience?: string;
  nr_ddd_celular?: string;
  nr_celular?: string;
  cd_entidade?: number;
  cd_cidade?: number;
  cd_filial?: number;
  cd_importacao?: number;
  cd_usuario?: number;
  ds_entidade?: string;
  ds_fantasia?: string;
  ds_endereco?: string;
  ds_bairro?: string;
  nr_cep?: string;
  nr_numero?: number;
  role?: string;
  diretoria?: number,


}

export default function createEmptyUser(): UserType  {
  return {
    id: 0,
    name: "",
    cpf_cnpj: "",
    password: "",
    email: "",
    audience: "",
    nr_ddd_celular: "",
    nr_celular: "",
    cd_entidade: 0,
    cd_cidade: 0,
    cd_filial: 0,
    cd_importacao: 0,
    cd_usuario: 0,
    ds_entidade: "",
    ds_fantasia: "",
    ds_endereco: "",
    ds_bairro: "",
    nr_cep: "",
    nr_numero: 0,
    role: "",
    }
}