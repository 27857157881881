import { PointTooltip, PointTooltipProps, ResponsiveLine } from "@nivo/line";
import { useTheme } from "@mui/material";
import { tokens } from "../theme";
import React, { useState } from "react";


interface LineChartProps {
  data: { id: string; data: { x: number | string; y: number }[] }[];
  isCustomLineColors?: boolean;
  isDashboard?: boolean;
}
const LineChart = ({ data, isCustomLineColors = false, isDashboard = false }: LineChartProps) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  // const [chartData, setChartData] = useState<any[]>([]);
  console.log("Linechart")
  console.log(data)

  const CustomTooltip = ({ point }: PointTooltipProps) => (
    <div style={{ background: 'white', padding: '5px', border: '1px solid #ccc', color: 'black' }}>
      <strong>{point.serieId}</strong> {/* Categoria */}
      <br />
      Ano e Mês: {point.data.xFormatted} {/* x Value */}
      <br />
      Valor: {Number(point.data.y).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} {/* y Value */}
    </div>
  );

  return (
    <ResponsiveLine
    data={data}
    theme={{
      axis: {
        domain: {
          line: {
            stroke: colors.grey[100],
          },
        },
        legend: {
          text: {
            fill: colors.grey[100],
          },
        },
        ticks: {
          line: {
            stroke: colors.grey[100],
            strokeWidth: 1,
          },
          text: {
            fill: colors.grey[100],
          },
        },
      },
      legends: {
        text: {
          fill: colors.grey[100],
        },
      },
      tooltip: {
        container: {
          background: 'white',
          color: 'black',
        },
      },
    }}
    colors={{ scheme: "nivo" }}
    lineWidth={2}
    margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
    xScale={{ type: 'point' }}
    yScale={{
      type: 'linear',
      min: 0,
      max: 'auto',
      stacked: false,
      reverse: false,
    }}
    yFormat=" >-.2f"
    curve="catmullRom"
    axisTop={null}
    axisRight={null}
    axisBottom={{
      tickSize: 0,
      tickPadding: 5,
      tickRotation: 0,
      legend: isDashboard ? undefined : "Ano e Mês", // Renomeando legenda do eixo X
      legendOffset: 36,
      legendPosition: "middle",
    }}
    axisLeft={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: isDashboard ? undefined : "Valor", // Renomeando legenda do eixo Y
      legendOffset: -40,
      legendPosition: "middle",
    }}
    enableGridX={false}
    enableGridY={false}
    pointSize={8}
    pointColor={{ theme: "background" }}
    pointBorderWidth={2}
    pointBorderColor={{ from: "serieColor" }}
    pointLabelYOffset={-12}
    useMesh={true}
    tooltip={CustomTooltip} // Usando tooltip personalizado
    legends={[
      {
        anchor: "top-right",
        direction: "column",
        justify: false,
        translateX: 95,
        translateY: 0,
        itemsSpacing: 0,
        itemDirection: "left-to-right",
        itemWidth: 80,
        itemHeight: 20,
        itemOpacity: 0.75,
        symbolSize: 12,
        symbolShape: "circle",
        symbolBorderColor: "rgba(0, 0, 0, .5)",
        effects: [
          {
            on: "hover",
            style: {
              itemBackground: "rgba(0, 0, 0, .03)",
              itemOpacity: 1,
            },
          },
        ],
      },
    ]}
  />  
);
};

export default LineChart;
