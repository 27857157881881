import { ENDPOINTS } from '../configs/apiConfigs'; // Ajuste o caminho conforme necessário
import jwtDecode from 'jwt-decode';
import axiosInstance from './axiosConfig';

interface LoginServiceProps {
  email: string;
  password: string;
}

class AuthenticationService {
  // Método para realizar o login
  // public async login({ email, password }: LoginServiceProps): Promise<any> {
  //   try {
  //     const response = await fetch(ENDPOINTS.authentication.login, {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify({ email, password, "application": "all" }),
  //     });

  //     const data = await response.json();

  //     if (!response.ok) {
  //       throw new Error('Falha na autenticação');
  //     }

  //     // console.log("Resposta do login");
  //     // console.log(data);

  //     return { data };
  //   } catch (error) {
  //     console.error("Erro ao logar:", error);
  //     return false; // Retornar false ou lançar um erro dependendo de como você quer manejar falhas de login
  //   }
  // }


  public async login({ email, password }: LoginServiceProps): Promise<any> {
    try {
      const response = await axiosInstance.post(ENDPOINTS.authentication.login, {
        email,
        password,
        application: 'all',
      });

      return { data: response.data };
    } catch (error) {
      console.error('Erro ao logar:', error);
      throw new Error('Falha na autenticação');
    }
  }
  // Método para realizar o logout
  public static logout(): void {
    // Remover o token de autenticação do localStorage ou manejar o logout conforme necessário
    localStorage.removeItem('access_token');
    localStorage.removeItem('auth');
    localStorage.removeItem('isAuthenticated');
    localStorage.removeItem('decodedToken');
    window.location.reload();
  }
}

export default new AuthenticationService();
