// import axios from "axios";
import { NotasEmitidasSemCentroType, NotasEmitidasType } from "../models/NotasEmitidas";
import { ENDPOINTS } from "../configs/apiConfigs"; // Ajuste o caminho conforme necessário

import axiosInstance from './axiosConfig'; // Ajuste o caminho conforme necessário

const axios = axiosInstance;

class NotasEmitidasService {
    private API_URL = ENDPOINTS.notas_emitidas;
    private API_URL_centro = ENDPOINTS.notas_emitidas_sem_centro;


    public getStatusText = (cd_status: number | undefined): string => {
        switch (cd_status) {
            case 1:
                return "Pendente";
            case 2:
                return "Faturada";
            case 4:
                return "Cancelada";
            default:
                return "Status desconhecido";
        }
    };
    public colunaDescricao(itens:NotasEmitidasType[]) : NotasEmitidasType[] {

        itens = itens.map(
            item => ({...item, ds_status: this.getStatusText(item.cd_status)})
        )
        
        return itens;

    }
    // Método para obter todos os custos desdobrados
    public async getAllItens(): Promise<NotasEmitidasType[]> {
        try {
            const response = await axios.get(this.API_URL);
            console.log(response.data);
            
            return this.colunaDescricao(response.data.reverse());
        } catch (error) {
            throw new Error(`Erro ao obter custos desdobrados: ${error}`);
        }
    }
    // Método para obter todos os custos desdobrados
    public async getAllItensSemCentro(): Promise<NotasEmitidasSemCentroType[]> {
        try {
            const response = await axios.get(this.API_URL_centro);
            console.log(response.data);
            
            return response.data;
        } catch (error) {
            throw new Error(`Erro ao obter custos desdobrados: ${error}`);
        }
    }

    // Método para obter um custo desdobrado por ID
    public async getItemById(id: number): Promise<NotasEmitidasType> {
        try {
            const response = await axios.get(`${this.API_URL}/${id}`);
            return this.colunaDescricao(response.data)[0];

            // return response.data;
        } catch (error) {
            throw new Error(`Erro ao obter custo desdobrado: ${error}`);
        }
    }
    // Método para obter um custo desdobrado por ID
    public async getItemByEntidadeId(entidadeId: number): Promise<NotasEmitidasType[]> {
        try {
            console.log(`${this.API_URL}/entidade/${entidadeId}`);
            
            const response = await axios.get(`${this.API_URL}/entidade/${entidadeId}`);
            return this.colunaDescricao(response.data.reverse());

        } catch (error) {
            throw new Error(`Erro ao obter custo desdobrado: ${error}`);
        }
    }

    // Método para criar um novo custo desdobrado
    public async createItem(custo: NotasEmitidasType): Promise<NotasEmitidasType> {
        try {
            const response = await axios.post(this.API_URL, custo);
            return response.data;
        } catch (error) {
            throw new Error(`Erro ao criar custo desdobrado: ${error}`);
        }
    }

    // Método para atualizar um custo desdobrado
    public async updateItem(id: number, custo: NotasEmitidasType): Promise<NotasEmitidasType> {
        try {
            const response = await axios.put(`${this.API_URL}/${id}`, custo);
            return response.data;
        } catch (error) {
            throw new Error(`Erro ao atualizar custo desdobrado: ${error}`);
        }
    }

    // Método para deletar um custo desdobrado
    public async deleteItem(id: number): Promise<void> {
        try {
            await axios.delete(`${this.API_URL}/${id}`);
        } catch (error) {
            throw new Error(`Erro ao deletar custo desdobrado: ${error}`);
        }
    }
}

export default new NotasEmitidasService();