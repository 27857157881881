// import axios from "axios";
import { NotasFaturamentoType } from "../models/NotasFaturamento";
import { ENDPOINTS } from "../configs/apiConfigs"; // Ajuste o caminho conforme necessário
import axiosInstance from './axiosConfig'; // Ajuste o caminho conforme necessário
import { allowedRoles } from "../configs/apiConfigs"; // Supondo que você tenha esses grupos configurados
import { UserProfile } from "../models/User";

const axios = axiosInstance;

class NotasFaturamentoService {
    private API_URL = ENDPOINTS.notas_faturamento;

    // Método para obter todas as notas de faturamento
    public async getAllItens(user: UserProfile): Promise<NotasFaturamentoType[]> {
        try {
            let response = await axios.get(this.API_URL);
            let dados = response.data;
            console.log(user)
            if (!allowedRoles.includes(user?.role ?? '')) {
                throw new Error('Acesso negado para este usuário');
            }
            console.log(response.data)
            if (user?.role === 'diretor') {
                // console.log(user)
                // console.log(user?.diretoria_descricao?.toLocaleLowerCase());
                
                // Se o usuário é da diretoria, filtra apenas os itens de diretoria DESCRIÇÃO
                dados = dados.filter((item: NotasFaturamentoType) => item.grupo?.toLocaleLowerCase() === user?.diretoria_descricao?.toLocaleLowerCase());
            } 

            return dados.reverse(); // Ou outra manipulação que você necessite
        } catch (error) {
            throw new Error(`Erro ao obter notas de faturamento: ${error}`);
        }
    }

    // Método para obter notas de faturamento por cliente (cd_cliente)
    public async getItemByCdCliente(cd_cliente: number): Promise<NotasFaturamentoType[]> {
        try {
            const response = await axios.get(`${this.API_URL}/cliente/${cd_cliente}`);
            return response.data;
        } catch (error) {
            throw new Error(`Erro ao obter notas de faturamento por cliente: ${error}`);
        }
    }

    // Método para obter uma nota de faturamento por ID
    public async getItemById(id: number): Promise<NotasFaturamentoType> {
        try {
            const response = await axios.get(`${this.API_URL}/${id}`);
            return response.data;
        } catch (error) {
            throw new Error(`Erro ao obter nota de faturamento: ${error}`);
        }
    }

    // Método para criar uma nova nota de faturamento
    public async createItem(nota: NotasFaturamentoType): Promise<NotasFaturamentoType> {
        try {
            const response = await axios.post(this.API_URL, nota);
            return response.data;
        } catch (error) {
            throw new Error(`Erro ao criar nota de faturamento: ${error}`);
        }
    }

    // Método para criar notas de faturamento em lote
    public async createItemsBatch(notas: NotasFaturamentoType[]): Promise<NotasFaturamentoType[]> {
        try {
            const response = await axios.post(`${this.API_URL}/lote`, notas);
            return response.data;
        } catch (error) {
            throw new Error(`Erro ao criar notas de faturamento em lote: ${error}`);
        }
    }

    // Método para atualizar uma nota de faturamento
    public async updateItem(id: number, nota: NotasFaturamentoType): Promise<NotasFaturamentoType> {
        try {
            const response = await axios.put(`${this.API_URL}/${id}`, nota);
            return response.data;
        } catch (error) {
            throw new Error(`Erro ao atualizar nota de faturamento: ${error}`);
        }
    }

    // Método para deletar uma nota de faturamento
    public async deleteItem(id: number): Promise<void> {
        try {
            await axios.delete(`${this.API_URL}/${id}`);
        } catch (error) {
            throw new Error(`Erro ao deletar nota de faturamento: ${error}`);
        }
    }

    // Método para deletar todas as notas de faturamento
    public async deleteAllData(hash_client: string): Promise<void> {
        try {
            await axios.delete(`${this.API_URL}/destroy_all_data/${hash_client}`);
        } catch (error) {
            throw new Error(`Erro ao deletar todas as notas de faturamento: ${error}`);
        }
    }
}

export default new NotasFaturamentoService();
