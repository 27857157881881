import { DiretoriaType } from "../models/Diretoria"; // Ajuste o caminho conforme necessário
import { ENDPOINTS } from "../configs/apiConfigs"; // Ajuste o caminho conforme necessário
import axiosInstance from './axiosConfig'; // Ajuste o caminho conforme necessário

const axios = axiosInstance;

class DiretoriaService {

    // Método para obter todas as diretorias
    public async getAllDiretorias(): Promise<DiretoriaType[]> {
        try {
            const response = await axios.get<DiretoriaType[]>(ENDPOINTS.diretoria);
            return response.data;
        } catch (error) {
            console.error("Erro ao buscar diretorias:", error);
            throw error;
        }
    }

    // Método para obter uma diretoria por ID
    public async getDiretoriaById(id: number): Promise<DiretoriaType> {
        try {
            const response = await axios.get<DiretoriaType>(`${ENDPOINTS.diretoria}/${id}`);
            return response.data;
        } catch (error) {
            console.error(`Erro ao buscar a diretoria com ID ${id}:`, error);
            throw error;
        }
    }

    // Método para criar uma nova diretoria
    public async createDiretoria(diretoria: DiretoriaType): Promise<DiretoriaType> {
        try {
            const response = await axios.post<DiretoriaType>(ENDPOINTS.diretoria, diretoria);
            return response.data;
        } catch (error) {
            console.error("Erro ao criar diretoria:", error);
            throw error;
        }
    }

    // Método para atualizar uma diretoria existente por ID
    public async updateDiretoria(id: number, diretoria: DiretoriaType): Promise<DiretoriaType> {
        try {
            const response = await axios.put<DiretoriaType>(`${ENDPOINTS.diretoria}/${id}`, diretoria);
            return response.data;
        } catch (error) {
            console.error(`Erro ao atualizar a diretoria com ID ${id}:`, error);
            throw error;
        }
    }

    // Método para excluir uma diretoria por ID
    public async deleteDiretoria(id: number): Promise<void> {
        try {
            await axios.delete(`${ENDPOINTS.diretoria}/${id}`);
        } catch (error) {
            console.error(`Erro ao excluir a diretoria com ID ${id}:`, error);
            throw error;
        }
    }
}

export default new DiretoriaService();
