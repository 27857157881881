// import axios from "axios";
import { UserType } from "../models/User";
import { ENDPOINTS } from "../configs/apiConfigs";
import axiosInstance from './axiosConfig'; // Ajuste o caminho conforme necessário

const axios = axiosInstance;

class UserGestaoService {
    private API_URL = ENDPOINTS.users.update;
    private API_URL_CREATE = ENDPOINTS.users.create_email;



    public async createUsuario(usuario:UserType): Promise<UserType[]> {
        // checagem de permissão de usuário
        try {
            const response = await axios.put(this.API_URL_CREATE + "/" + usuario.email, usuario);
            console.log(response.data)

            return response.data;
        } catch (error) {
            throw new Error(`Erro ao obter os usuários: ${error}`)
            
        }


    }
    public async getAllUsers(): Promise<UserType[]> {
        // checagem de permissão de usuário
        try {
            const response = await axios.get(this.API_URL);
            console.log(response.data)

            return response.data;
        } catch (error) {
            throw new Error(`Erro ao obter os usuários: ${error}`)
            
        }


    }
    public async getUserById(id:number): Promise<UserType> {
        // checagem de permissão de usuário
        try {
            const response = await axios.get(`${this.API_URL}/${id}`);
            console.log(response.data)

            return response.data;
        } catch (error) {
            throw new Error(`Erro ao obter os usuários: ${error}`)
            
        }


    }

    public async getUserByEmail(email:string): Promise<UserType> {
        // checagem de permissão de usuário
        try {
            const response = await axios.get(`${this.API_URL}/email/${email}`);
            console.log(response.data)

            return response.data;
        } catch (error) {
            throw new Error(`Erro ao obter os usuários: ${error}`)
            
        }
    }
    public async updateUsuario( userEdit:UserType, id?:number): Promise<UserType> {
        // checagem de permissão de usuário
        try {
            console.log(`${this.API_URL}/${id}`);
            console.log(userEdit);
            
            const response = await axios.put(`${this.API_URL}/update/${id}`, userEdit);
            console.log(response.data)

            return response.data;
        } catch (error) {
            throw new Error(`Erro ao atualizar o usuário: ${error}`)
            
        }
    }
    public async deleteUsuario(id:number): Promise<UserType> {
        // checagem de permissão de usuário
        try {
            const response = await axios.delete(`${this.API_URL}/${id}`);
            console.log(response.data)

            return response.data;
        } catch (error) {
            throw new Error(`Erro ao apagar os usuários: ${error}`)
            
        }
    }

}

export default new UserGestaoService();