import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, useTheme, Typography } from '@mui/material';
import { DataGrid, GridRenderCellParams, GridToolbar, ptBR } from '@mui/x-data-grid';
import { tokens } from "../../theme";
import { NotasEmitidasSemCentroType } from '../../models/NotasEmitidas';
import notasEmitidasService from '../../services/notasEmitidasService';
import StyledButton from '../../components/StyledButton';

interface NotaDialogProps {
    open: boolean;
    onClose: () => void;
}



const NotaSemCentroDialog: React.FC<NotaDialogProps> = ({ open, onClose }) => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [items, setItems] = useState<NotasEmitidasSemCentroType[]>([]);

    useEffect(() => {


        const fetchItens = async () => {
            try {
                let itens = null;
                // caso seja role admin_master ou gestor_mater, pode ter acesso a todos
                itens = await notasEmitidasService.getAllItensSemCentro();

                // converter para numero o numero da nota
                if (itens) {
                    itens = itens.map(item => ({
                        ...item,
                        nr_documento: Number(item.nr_documento) // Garantindo que nr_documento seja um número
                    }));
                }

                setItems(itens)
            } catch (error) {
                console.log("Erro ao buscar itens desdobrados: ", error)
            }
        };


        fetchItens();
    }, []);


    const columnsCustos = [
        { field: 'cd_lancamento', headerName: 'CD Lancamento', width: 150 },
        { field: 'nr_documento', headerName: 'NR Documento', width: 150 },
        {
            field: 'dt_emissao', headerName: 'Data Emissao',
            renderCell: (params: GridRenderCellParams<Date>) => (
                <Typography color={colors.greenAccent[500]}>
                    {new Intl.DateTimeFormat('pt-BR', {
                        dateStyle: 'medium'
                    }).format(new Date(params.row.dt_emissao))}
                </Typography>
            ), width: 200
        },

        {
            field: 'vl_total', headerName: 'Valor Total',
            renderCell: (params: GridRenderCellParams<number>) => (
                <Typography color={colors.greenAccent[500]}>
                    {/* R$ {params.row.vl_custo} */}
                    {new Intl.NumberFormat('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                    }).format(params.row.vl_total)}
                </Typography>
            ),
            width: 150
        },
        { field: 'ds_obs', headerName: 'Observação', width: 250 },
        { field: 'cd_entidade', headerName: 'CD Entidade', width: 150 },
        { field: 'name', headerName: 'Nome', width: 200 },
        { field: 'cpf_cnpj', headerName: 'CPF/CNPJ', width: 200 },
        { field: 'lancamentos', headerName: 'Lançamentos', width: 150 },
    ];
    return (
        <Dialog open={open} onClose={onClose} fullWidth fullScreen>
            <DialogTitle sx={{ backgroundColor: colors.greenAccent[300], fontSize: "2em" }}>
                Notas Sem Centro de Custo
            </DialogTitle>

            <DialogContent sx={{ backgroundColor: colors.primary[400], color: colors.primary[300] }}>
                <DataGrid
                    componentsProps={{
                        toolbar: {
                            filter: {
                                filterModel: {
                                    items: [],
                                    quickFilterValues: ['quick', 'filter'],
                                },
                            },
                            printOptions: { disableToolbarButton: true },
                            csvOptions: { disableToolbarButton: true },
                            sx: {
                                '& .MuiButton-root': {
                                    color: 'white', backgroundColor: colors.greenAccent[300], '&:hover': {
                                        backgroundColor: 'darkblue',
                                    },
                                    m: '5px'
                                },
                            },
                        },
                    }}
                    components={{
                        Toolbar: GridToolbar,
                    }}
                    localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                    rows={items}
                    columns={columnsCustos}
                />
            </DialogContent>

            <DialogActions sx={{ backgroundColor: colors.greenAccent[300] }}>

                <StyledButton
                    onClick={onClose}
                    colorVariant="gray">
                    Fechar
                </StyledButton>
            </DialogActions>
        </Dialog>
    );
};

export default NotaSemCentroDialog;
