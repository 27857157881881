// src/components/NotasFaturamento.js

import React, { useEffect, useState } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, TextField, useTheme } from "@mui/material";
import { DataGrid, GridColDef, GridRenderCellParams, GridToolbar, ptBR } from '@mui/x-data-grid';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Header from "../../components/Header";
import { tokens } from "../../theme";
import NotasFaturamentoService from "../../services/notasFaturamentoService";
import { NotasFaturamentoType, TotaisNotasFaturamentoType, TotaisPorGrupoSubGrupoType } from "../../models/NotasFaturamento";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import AccountTree from "@mui/icons-material/AccountTree";
import exportToExcel from "../../components/exportToExcel";
import { useAuth } from '../../context/useAuth';
import { allowedRoles } from "../../configs/apiConfigs";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ptBR as localePtBR } from "date-fns/locale";
import { Autocomplete, createFilterOptions } from '@mui/material';
import CustomCard from "../../components/CustomCard";
import AutoAwesomeMotion from "@mui/icons-material/AutoAwesomeMotion";
import CreditCardOff from "@mui/icons-material/CreditCardOff";
import CurrencyExchange from "@mui/icons-material/CurrencyExchange";
import { somarPorGrupo } from "./helper";
import { dataGridHeaderStyles } from "../../components/styles/dataGridStyles"; // Importe o objeto de estilos
import StyledButton from "../../components/StyledButton";

const NotasFaturamento = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { user } = useAuth();
    const [items, setItems] = useState<NotasFaturamentoType[]>([]);
    const [filteredItems, setFilteredItems] = useState<NotasFaturamentoType[]>([]);
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [dataInicial, setDataInicial] = useState<Date | null>(null);
    const [dataFinal, setDataFinal] = useState<Date | null>(null);
    const [cidade, setCidade] = useState<string>('');
    const [estado, setEstado] = useState<string>('');
    const [cidadesDisponiveis, setCidadesDisponiveis] = useState<string[]>([]);
    const [estadosDisponiveis, setEstadosDisponiveis] = useState<string[]>([]);
    const [totaisNotas, setTotaisNotas] = useState<TotaisNotasFaturamentoType>({
        totalICMS: 0,
        totalValor: 0,
        quantidadeNotas: 0,
    });
    const [totais, setTotais] = useState<TotaisPorGrupoSubGrupoType[]>([]);

    // Adicionar os estados para grupo e subgrupo
    const [grupo, setGrupo] = useState<string>('');
    const [subgrupo, setSubgrupo] = useState<string>('');
    const [gruposDisponiveis, setGruposDisponiveis] = useState<string[]>([]);
    const [subgruposDisponiveis, setSubgruposDisponiveis] = useState<string[]>([]);

    const fetchItens = async () => {
        try {
            let itens: NotasFaturamentoType[] = [];

            itens = await NotasFaturamentoService.getAllItens(user!);

            // Processamento dos itens
            itens = itens.map(item => ({
                ...item,
                nr_documento: Number(item.nr_documento) || 0, // Garantindo que nr_documento seja um número
            }));

            setItems(itens);
            setFilteredItems(itens); // Inicializa os itens filtrados
            calcularTotais(itens);
            const cidadesUnicas = Array.from(new Set(itens.map(item => item.ds_cidade || '')));
            const estadosUnicos = Array.from(new Set(itens.map(item => item.ds_uf || '')));
            const gruposUnicos = Array.from(new Set(itens.map(item => item.grupo || '')));
            const subgruposUnicos = Array.from(new Set(itens.map(item => item.subgrupo || '')));

            setCidadesDisponiveis(cidadesUnicas);
            setEstadosDisponiveis(estadosUnicos);
            setGruposDisponiveis(gruposUnicos);
            setSubgruposDisponiveis(subgruposUnicos);

            setTotais(somarPorGrupo(itens));
            console.log("totais", totais);

        } catch (error) {
            console.log("Erro ao buscar itens: ", error);
        }
    };

    useEffect(() => {
        fetchItens();
    }, []);

    const handleOpenDialog = (item: NotasFaturamentoType) => {
        // Implementar lógica para abrir o diálogo com detalhes do item
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleOpenDialogNotasSemCentro = () => {
        // Implementar lógica para abrir o diálogo com notas sem centro
    };

    const handleFilter = () => {
        const filtered = items.filter(item => {
            // Verificação para evitar problemas com `null` ou `undefined`
            const itemDate = item.dt_lancamento ? new Date(item.dt_lancamento) : null;
            const dateMatch = (itemDate && dataInicial && dataFinal)
                ? itemDate >= dataInicial && itemDate <= dataFinal
                : true;

            const cityMatch = cidade
                ? item.ds_cidade?.toLowerCase().includes(cidade.toLowerCase()) ?? false
                : true;

            const stateMatch = estado
                ? item.ds_uf?.toLowerCase() === estado.toLowerCase()
                : true;


            const grupoMatch = grupo
                ? item.grupo?.toLowerCase() === grupo.toLowerCase()
                : true;

            const subgrupoMatch = subgrupo
                ? item.subgrupo?.toLowerCase() === subgrupo.toLowerCase()
                : true;

            return dateMatch && cityMatch && stateMatch && grupoMatch && subgrupoMatch;

        });
        setFilteredItems(filtered);
        calcularTotais(filtered);
        setTotais(somarPorGrupo(filtered));
    };

    const calcularTotais = (dados: NotasFaturamentoType[]) => {
        const totalICMS = dados.reduce((acc, item) => acc + (Number(item.vl_icms) || 0), 0);
        const totalValor = dados.reduce((acc, item) => acc + (Number(item.vl_total) || 0), 0);
        const quantidadeNotas = dados.length;

        setTotaisNotas({
            totalICMS,
            totalValor,
            quantidadeNotas,
        });
    };

    // Função para limpar filtros
    const limparFiltros = () => {
        setDataInicial(null);
        setDataFinal(null);
        setCidade('');
        setEstado('');
        setGrupo('');
        setSubgrupo('');
        setFilteredItems(items); // Restaurar os itens filtrados para todos os itens
        calcularTotais(items); // Recalcular os totais com todos os itens
        setTotais(somarPorGrupo(items)); // Recalcular os totais por grupo e subgrupo
    };

    const columns: GridColDef[] = [
        { field: "cd_lancamento", headerName: "Lançamento", flex: 1, minWidth: 100 },
        { field: "nr_documento", headerName: "Nota (NF)", flex: 1, minWidth: 100 },
        {
            field: "dt_lancamento",
            headerName: "Dt Lançamento",
            flex: 1.5,
            minWidth: 150,
            renderCell: (params: GridRenderCellParams<string>) => (
                <Typography color={colors.greenAccent[500]}>
                    {new Intl.DateTimeFormat('pt-BR', { dateStyle: 'medium' }).format(new Date(params.row.dt_lancamento))}
                </Typography>
            ),
        },
        { field: "nr_percentual", headerName: "Percentual", flex: 1, minWidth: 100 },
        { field: "grupo_nota", headerName: "Grupo", flex: 1, minWidth: 100 },
        { field: "subgrupo_nota", headerName: "Subgrupo", flex: 1.5, minWidth: 150 },
        { field: "nr_quantidade", headerName: "Quantidade", flex: 1, minWidth: 100 },
        { field: "nr_itens", headerName: "Nº de Itens", flex: 1, minWidth: 100 },
        {
            field: "vl_total",
            headerName: "Valor Total",
            flex: 1.3,
            minWidth: 150,
            renderCell: (params: GridRenderCellParams<number>) => (
                <Typography color={colors.greenAccent[500]}>
                    {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(params.row.vl_total)}
                </Typography>
            ),
        },
        {
            field: "vl_base_icms",
            headerName: "Base ICMS",
            flex: 1,
            minWidth: 150,
            renderCell: (params: GridRenderCellParams<number>) => (
                <Typography color={colors.greenAccent[500]}>
                    {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(params.row.vl_base_icms)}
                </Typography>
            ),
        },
        {
            field: "vl_icms",
            headerName: "Valor ICMS",
            flex: 1,
            minWidth: 150,
            renderCell: (params: GridRenderCellParams<number>) => (
                <Typography color={colors.greenAccent[500]}>
                    {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(params.row.vl_icms)}
                </Typography>
            ),
        },
        { field: "name", headerName: "Cliente", flex: 2, minWidth: 200 },
        { field: "ds_cidade", headerName: "Cidade", flex: 1.2, minWidth: 120 },
        { field: "ds_uf", headerName: "UF", flex: 1, minWidth: 100 },
    ];

    return (
        <Box m="20px">
            <Header title="Notas Faturadas" subtitle="Lista de Notas" />
            <Box
                display="flex"
                alignItems="center"
                mb="20px"
                sx={{
                    flexDirection: { xs: 'column', sm: 'row' },
                    '& > *': {
                        mb: { xs: 2, sm: 0 }, // Adiciona espaço entre os itens em telas pequenas
                        mr: { sm: 2, xs: 0 }, // Adiciona margem à direita em telas maiores
                    },
                }}
            >
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={localePtBR}>
                    <DatePicker
                        label="Data Inicial"
                        value={dataInicial}
                        onChange={(newValue) => setDataInicial(newValue)}
                        renderInput={(params) => <TextField {...params} sx={{ mr: { sm: 2, xs: 0 } }} />}
                    />
                    <DatePicker
                        label="Data Final"
                        value={dataFinal}
                        onChange={(newValue) => setDataFinal(newValue)}
                        renderInput={(params) => <TextField {...params} sx={{ mr: { sm: 2, xs: 0 } }} />}
                    />
                </LocalizationProvider>

                <Autocomplete
                    options={cidadesDisponiveis}
                    value={cidade}
                    onChange={(event, newValue) => setCidade(newValue || "")}
                    renderInput={(params) => <TextField {...params} label="Cidade" sx={{ mr: { sm: 2, xs: 0 } }} />}
                    filterOptions={createFilterOptions({
                        matchFrom: 'start',
                        stringify: option => option,
                    })}
                />
                <Autocomplete
                    options={estadosDisponiveis}
                    value={estado}
                    onChange={(event, newValue) => setEstado(newValue || "")}
                    renderInput={(params) => <TextField {...params} label="Estado" sx={{ mr: { sm: 2, xs: 0 } }} />}
                    filterOptions={createFilterOptions({
                        matchFrom: 'start',
                        stringify: option => option,
                    })}
                />

                <Autocomplete
                    options={gruposDisponiveis}
                    value={grupo}
                    onChange={(event, newValue) => setGrupo(newValue || "")}
                    renderInput={(params) => <TextField {...params} label="Grupo" sx={{ mr: { sm: 2, xs: 0 } }} />}
                    filterOptions={createFilterOptions({
                        matchFrom: 'start',
                        stringify: option => option,
                    })}
                />
                <Autocomplete
                    options={subgruposDisponiveis}
                    value={subgrupo}
                    onChange={(event, newValue) => setSubgrupo(newValue || "")}
                    renderInput={(params) => <TextField {...params} label="Subgrupo" sx={{ mr: { sm: 2, xs: 0 } }} />}
                    filterOptions={createFilterOptions({
                        matchFrom: 'start',
                        stringify: option => option,
                    })}
                />

            </Box>

            <Box sx={{ marginBottom: "15px" }}>
                <StyledButton onClick={handleFilter} colorVariant="gray">
                    Filtrar
                </StyledButton>
                <StyledButton onClick={limparFiltros} colorVariant="green">
                    Limpar Filtros
                </StyledButton>
                <StyledButton
                    onClick={() => exportToExcel(filteredItems, "Notas Faturadas")}
                    colorVariant="gray">
                    <DownloadOutlinedIcon sx={{ mr: "10px" }} />

                    Baixar em Excel
                </StyledButton>



            </Box>

            <Box
                display="grid"
                gridTemplateColumns="repeat(6, 1fr)"
                gridAutoRows="140px"
                gap="10px"
            >

                <CustomCard
                    title={`${new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(totaisNotas.totalValor)}`}
                    subtitle="Total Valor"
                    icon={
                        <CurrencyExchange
                            sx={{ color: colors.greenAccent[500], fontSize: "26px" }}
                        />
                    }
                />
                <CustomCard
                    title={`${new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(totaisNotas.totalICMS)}`}
                    subtitle="Total ICMS"
                    icon={
                        <CreditCardOff
                            sx={{ color: colors.greenAccent[500], fontSize: "26px" }}
                        />
                    }
                />
                <CustomCard
                    title={`${totaisNotas.quantidadeNotas}`}
                    subtitle="Total notas faturadas"
                    icon={
                        <AutoAwesomeMotion
                            sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                        />
                    }
                />

                {
                    totais.map((total, index) => (
                        <CustomCard
                            key={index}
                            title={`${new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(total.totalGrupo || total.totalSubGrupo)}`}
                            subtitle={`Grupo: ${total.grupo}, Subgrupo: ${total.subgrupo}`}
                            variant_subtitle="h5"
                            icon={
                                <AccountTree
                                    sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                                />
                            }
                        />
                    ))
                }


            </Box>

            <Box
                sx={{
                    ...dataGridHeaderStyles, // Aplica os estilos reutilizáveis
                    width: '100%',
                    height: '75vh',
                    overflowX: 'auto', // Adiciona rolagem horizontal
                }}
            >
                <DataGrid
                    componentsProps={{
                        toolbar: {
                            filter: {
                                filterModel: {
                                    items: [],
                                    quickFilterValues: ['quick', 'filter'],
                                },
                            },
                            printOptions: { disableToolbarButton: true },
                            csvOptions: { disableToolbarButton: true },
                            sx: {
                                '& .MuiButton-root': {
                                    color: 'white',
                                    backgroundColor: colors.greenAccent[400],
                                    '&:hover': {
                                        backgroundColor: 'darkblue',
                                    },
                                    margin: "5px",
                                },
                            },
                        },
                    }}
                    components={{
                        Toolbar: GridToolbar,
                    }}
                    localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                    rows={filteredItems}
                    columns={columns}
                />
            </Box>

            <Dialog open={openDialog} onClose={handleCloseDialog} fullWidth>
                <DialogTitle sx={{ backgroundColor: colors.blueAccent[700] }}>
                    Detalhes da nota
                </DialogTitle>
                <DialogContent sx={{ backgroundColor: colors.primary[400], color: colors.primary[300] }}>
                    <Box
                        sx={{
                            ...dataGridHeaderStyles, // Reaplica os estilos também no DataGrid dentro do diálogo
                        }}
                    >
                        <DataGrid
                            autoHeight
                            componentsProps={{
                                toolbar: {
                                    filter: {
                                        filterModel: {
                                            items: [],
                                            quickFilterValues: ['quick', 'filter'],
                                        },
                                    },
                                    printOptions: { disableToolbarButton: true },
                                    csvOptions: { disableToolbarButton: true },
                                    sx: {
                                        '& .MuiButton-root': {
                                            color: 'white',
                                            backgroundColor: colors.blueAccent[700],
                                            '&:hover': {
                                                backgroundColor: 'darkblue',
                                            },
                                            margin: "5px",
                                        },
                                    },
                                },
                            }}
                            components={{
                                Toolbar: GridToolbar,
                            }}
                            localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                            rows={filteredItems}
                            columns={columns}
                            sx={{
                                '& .MuiDataGrid-cell': {
                                    whiteSpace: 'normal', // Permite quebra de linha
                                    wordWrap: 'break-word', // Quebra palavras longas
                                },
                            }}
                        />
                    </Box>
                </DialogContent>
                <DialogActions sx={{ backgroundColor: colors.blueAccent[700] }}>
                    <Button onClick={handleCloseDialog} sx={{ color: colors.greenAccent[200] }}>Fechar</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default NotasFaturamento;
