// src/styles/dataGridStyles.js

export const dataGridHeaderStyles = {
    "& .MuiDataGrid-root": {
        border: "none",
    },
    "& .MuiDataGrid-cell": {
        borderBottom: "none",
        whiteSpace: 'normal', // Permite quebra de linha
        wordWrap: 'break-word', // Quebra palavras longas
    },
    "& .MuiDataGrid-columnHeaders": {
        // Aplicação do degradê vertical
        background: 'linear-gradient(to bottom, #d3d3d3, #333333)',
        borderBottom: "none",
        color: "#ffffff", // Define o texto dos headers em branco
        textAlign: 'center', // Centraliza o texto
        borderTopLeftRadius: '8px', // Arredonda o canto superior esquerdo
        borderTopRightRadius: '8px', // Arredonda o canto superior direito
        // Estilização das divisórias finas entre colunas com espaçamento
        '& .MuiDataGrid-columnSeparator': {
            color: '#333333', // Cor próxima ao cinza escuro
            width: '1px', // Espessura fina
            marginLeft: '6px', // Espaçamento à esquerda
            marginRight: '6px', // Espaçamento à direita
        },
        // Padding interno das células do cabeçalho
        '& .MuiDataGrid-columnHeaderTitle': {
            padding: '12px',
        },
    },
    "& .MuiDataGrid-virtualScroller": {
        backgroundColor: '#f5f5f5', // Ajuste conforme necessário
    },
    "& .MuiDataGrid-footerContainer": {
        borderTop: "none",
        backgroundColor: '#333333', // Ajuste conforme necessário
        color: '#ffffff', // Cor do texto no rodapé
    },
    "& .MuiCheckbox-root": {
        color: '#ffffff !important', // Ajuste conforme necessário
    },
    '@media (max-width: 600px)': { // Estilos para telas pequenas
        "& .MuiDataGrid-cell": {
            fontSize: "12px", // Tamanho menor para texto
        },
        "& .MuiButton-root": {
            fontSize: "12px", // Tamanho menor para botões
            padding: "5px 10px", // Menos padding
        },
        // Ajustes específicos para o cabeçalho em telas pequenas
        "& .MuiDataGrid-columnHeaders": {
            '& .MuiDataGrid-columnHeaderTitle': {
                padding: '8px', // Reduz o padding em telas pequenas
            },
        },
    },
};
