import React, { useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { ColorModeContext, useMode } from './theme';
import { UserProvider, useAuth } from './context/useAuth';
import Sidebar from './components/global/Sidebar';
import Topbar from './components/global/Topbar';
import Dashboard from './pages/dashboard';
import NotasEmitidas from './pages/notas_emitidas';
import NotasFaturamento from './pages/notas_faturamento';
import Usuarios from './pages/usuarios';
import Cooperados from './pages/cooperados';
import NotasAgrupadas from './pages/notas_agrupadas_mes';
import EstoquePage from './pages/estoque';
import Form from './pages/form';
import Bar from './pages/bar';
import Pie from './pages/pie';
import Line from './pages/line';
import FAQ from './pages/faq';
import Calendar from './pages/calendar/calendar';
import Geography from './pages/geography';
import LoginForm from './pages/login';
import { ToastContainer } from 'react-toastify';
import ContaMovimentoNotas from './pages/conta_movimento_notas';

function App() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(localStorage.getItem('isAuthenticated') === 'true');
  const { user } = useAuth();

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <UserProvider>
          <CssBaseline />
          <ToastContainer />

          <div className='login-content' >
              <Routes>
                <Route
                  path="/"
                  element={
                    !isAuthenticated ? (
                      <LoginForm setIsAuthenticated={setIsAuthenticated} />
                    ) : (
                      <Navigate replace to="/admin" />
                    )
                  }
                />

              </Routes>
            </div>

          <div className={`app ${isSidebar ? 'sidebar-open' : 'sidebar-closed'}`}>
            {isAuthenticated && <Sidebar isSidebar={isSidebar} />}
            <div className="main-content">
              {isAuthenticated && <Topbar setIsSidebar={setIsSidebar} />}
              <Routes>

                {isAuthenticated ? (
                  <>
                    <Route path="/admin" element={<Dashboard />} />
                    <Route path="/admin/notas_emitidas" element={<NotasEmitidas />} />
                    <Route path="/admin/notas_faturamento" element={<NotasFaturamento />} />
                    <Route path="/admin/usuarios" element={<Usuarios />} />
                    <Route path="/admin/cooperados" element={<Cooperados />} />
                    <Route path="/admin/conta_movimento_notas" element={<ContaMovimentoNotas />} />
                    <Route path="/admin/notas_agrupadas_mes" element={<NotasAgrupadas />} />
                    <Route path="/admin/estoque" element={<EstoquePage />} />
                    <Route path="/admin/form" element={<Form />} />
                    <Route path="/admin/bar" element={<Bar />} />
                    <Route path="/admin/pie" element={<Pie />} />
                    <Route path="/admin/line" element={<Line />} />
                    <Route path="/admin/faq" element={<FAQ />} />
                    <Route path="/admin/calendar" element={<Calendar />} />
                    <Route path="/admin/geography" element={<Geography />} />
                  </>
                ) : (
                  <Route path="*" element={<Navigate replace to="/" />} />
                )}
              </Routes>
            </div>
          </div>
        </UserProvider>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
