// src/services/axiosConfig.ts

import axios from "axios";
import { toast } from "react-toastify";

const axiosInstance = axios.create();

// Interceptor para respostas do Axios
axiosInstance.interceptors.response.use(
  (response) => response, // Retorna a resposta se estiver tudo certo
  (error) => {
    if (error.response && error.response.status === 401) {
      // Token expirado ou não autorizado
      localStorage.removeItem("access_token");
      localStorage.removeItem("user");
      localStorage.removeItem("isAuthenticated");

      // Redireciona para a página de login
      window.location.href = "/login";
      toast.warning("Sessão expirada. Faça login novamente.");
    }
    return Promise.reject(error); // Rejeita a promessa para que o erro possa ser tratado
  }
);

export default axiosInstance;
