// src/components/PasswordForm.jsx
import React from 'react';
import { Formik, Form } from 'formik';
import { Box, Button, TextField } from '@mui/material';
import { FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { updatePassword, updateUser } from '../../services/userService';

import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

interface FormValues {
    name: string;
    email: string;
    senha1: string;
    senha2: string;
}


interface PasswordFormProps {
    initialValues: FormValues;
    // handleFormSubmit: (values: FormValues, formikHelpers: FormikHelpers<FormValues>) => void;
    isNonMobile: boolean;
    handleCloseDialog: () => void; // Adicionar esta linha

}



const validationSchema = Yup.object({
    name: Yup.string().required('Obrigatório'),
    email: Yup.string().email('E-mail inválido').required('Obrigatório'),
    senha1: Yup.string().required('Obrigatório'),
    senha2: Yup.string().oneOf([Yup.ref('senha1'), null], 'Senhas devem coincidir').required('Obrigatório')
});


const handleFormSubmit = async (values: FormValues) => {
    // alert(values.name);
    try {
        const { success, error } = await updatePassword(
            values.email,
            values.name,
            values.senha1
        );

        toast.success("Senha alterada com sucesso!", {
            position: "top-center"
          });
    } catch (error) {
        console.log(error);
        // alert(error)

        toast.error("Erro: " + error, {
            position: "top-left"
          });


    }

}

const PasswordForm: React.FC<PasswordFormProps> = ({ initialValues, isNonMobile, handleCloseDialog }) => {
    return (
        <div>
            <Formik
                initialValues={initialValues}
                onSubmit={(values) => {
                    handleFormSubmit(values);
                    handleCloseDialog(); // Chama para fechar o diálogo após submeter o formulário
                }}
                validationSchema={validationSchema}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                }) => (
                    <Form onSubmit={handleSubmit}>
                        <Box
                            display="grid"
                            gap="30px"
                            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                            sx={{
                                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                            }}
                        >
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Nome"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.name}
                                name="name"
                                error={!!touched.name && !!errors.name}
                                helperText={(touched.name && typeof errors.name === 'string') ? errors.name : ''}
                                sx={{ gridColumn: "span 4" }}
                                disabled
                            />

                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Email"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.email}
                                name="email"
                                error={!!touched.email && !!errors.email}
                                helperText={(touched.email && typeof errors.email === 'string') ? errors.email : ''}
                                sx={{ gridColumn: "span 4" }}
                                disabled
                            />

                            <TextField
                                fullWidth
                                variant="filled"
                                type="password"
                                label="Senha"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.senha1}
                                name="senha1"
                                error={!!touched.senha1 && !!errors.senha1}
                                helperText={(touched.senha1 && typeof errors.senha1 === 'string') ? errors.senha1 : ''}
                                sx={{ gridColumn: "span 4" }}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                type="password"
                                label="Repita a senha"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.senha2}
                                name="senha2"
                                error={!!touched.senha2 && !!errors.senha2}
                                helperText={(touched.senha2 && typeof errors.senha2 === 'string') ? errors.senha2 : ''}
                                sx={{ gridColumn: "span 4" }}
                            />
                        </Box>

                        <Box display="flex" justifyContent="end" mt="20px">

                            <Button type="button" color="primary" variant="contained" onClick={handleCloseDialog}>
                                Cancelar
                            </Button>

                            <Button sx={{ marginLeft: "5px" }} type="submit" color="secondary" variant="contained">
                                Atualizar dados
                            </Button>

                        </Box>
                    </Form>
                )}


            </Formik>
            <ToastContainer />
        </div>  
    );
};

export default PasswordForm;
