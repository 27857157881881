// import axios from "axios";
import { NotasAgrupadasType } from "../models/NotasAgrupadas";
import { ENDPOINTS } from "../configs/apiConfigs"; // Ajuste o caminho conforme necessário
import axiosInstance from './axiosConfig'; // Ajuste o caminho conforme necessário
import { UserProfile } from "../models/User";

const axios = axiosInstance;


class NotasAgrupadasMesService {
    private API_URL = ENDPOINTS.notas_agrupadas;

    // Método para obter todos os Notas Agrupadas
    public async getAllItens(user: UserProfile): Promise<NotasAgrupadasType[]> {
        try {
            const response = await axios.get(this.API_URL);
            console.log(response.data);

            const renamedData = this.renameCentroCusto(response.data);
            const sortedData = this.sortCentroCusto(renamedData);
            let finalData = this.splitCentroCusto(sortedData);

            // checagem de diretoria que o usuário é....
            if (user?.role === 'diretor') {
                // console.log(user)
                // console.log(user?.diretoria_descricao?.toLocaleLowerCase());
                
                // Se o usuário é da diretoria, filtra apenas os itens de diretoria DESCRIÇÃO
                finalData = finalData.filter((item: NotasAgrupadasType) => item.grupo_nota?.toLocaleLowerCase() === user?.diretoria_descricao?.toLocaleLowerCase());
            } 

            return finalData;
        } catch (error) {
            throw new Error(`Erro ao obter Notas Agrupadas: ${error}`);
        }
    }


        // Função para renomear os valores de ds_centro_custo
    public renameCentroCusto = (data: NotasAgrupadasType[]): NotasAgrupadasType[] => {
        return data.map(item => {
            let newCentroCusto = item.ds_centro_custo;
            
            // Adicione as regras de renomeação aqui
            if (newCentroCusto === "TOTAL NOTA") {
                newCentroCusto = "1_TOTAL NOTA";  // Exemplo de renomeação
            }
            if (newCentroCusto === "MOVIMENTAÇÃO PRODUTO COOPERADO") {
                newCentroCusto = "2_MOVIMENTAÇÃO PRODUTO COOPERADO";  // Exemplo de renomeação
            }
            if (newCentroCusto === "FUNRURAL") {
                newCentroCusto = "3_FUNRURAL";  // Exemplo de renomeação
            }
            if (newCentroCusto === "TAXA ADMINISTRATIVA TOTAL") {
                newCentroCusto = "4_TAXA ADMINISTRATIVA TOTAL";  // Exemplo de renomeação
            }
            if (newCentroCusto === "TAXA ADMINISTRATIVA") {
                newCentroCusto = "5_TAXA ADMINISTRATIVA DO ADMINISTRATIVO";  // Exemplo de renomeação
            }
            if (newCentroCusto === "TAXA ADMINISTRATIVA_GRUPO") {
                newCentroCusto = "6_TAXA ADMINISTRATIVA GRUPO";  // Exemplo de renomeação
            }
            if (newCentroCusto === "CAPITAL SOCIAL SOBRE MOVIMENTAÇÃO") {
                newCentroCusto = "7_CAPITAL SOCIAL SOBRE MOVIMENTAÇÃO";  // Exemplo de renomeação
            }
            // Adicione mais regras conforme necessário
            
            return {
                ...item,
                ds_centro_custo: newCentroCusto
            };
        });
    };


    // Função para renomear os valores de ds_centro_custo
    public splitCentroCusto = (data: NotasAgrupadasType[]): NotasAgrupadasType[] => {
        return data.map(item => {
            const parts = item.ds_centro_custo!.split('_');
            const newCentroCusto = parts.length > 1 ? parts[1] : item.ds_centro_custo;
            
            return {
                ...item,
                ds_centro_custo: newCentroCusto
            };
        });
    };

        // Função para ordenar os itens pelo campo ds_centro_custo
    public sortCentroCusto = (data: NotasAgrupadasType[]): NotasAgrupadasType[] => {
        return data.sort((a, b) => a.ds_centro_custo!.localeCompare(b.ds_centro_custo!));
    };


    // Método para obter um Item por ID
    public async getItemById(id: number): Promise<NotasAgrupadasType> {
        try {
            const response = await axios.get(`${this.API_URL}/${id}`);
            return response.data;
        } catch (error) {
            throw new Error(`Erro ao obter Item: ${error}`);
        }
    }
    // Método para obter um Item por ID
    public async getItemByEntidadeId(entidadeId: number): Promise<NotasAgrupadasType[]> {
        try {
            console.log(`${this.API_URL}/entidade/${entidadeId}`);
            
            const response = await axios.get(`${this.API_URL}/entidade/${entidadeId}`);
            return response.data;
        } catch (error) {
            throw new Error(`Erro ao obter Item: ${error}`);
        }
    }

    // Método para criar um novo Item
    public async createItem(item: NotasAgrupadasType): Promise<NotasAgrupadasType> {
        try {
            const response = await axios.post(this.API_URL, item);
            return response.data;
        } catch (error) {
            throw new Error(`Erro ao criar Item: ${error}`);
        }
    }

    // Método para atualizar um Item
    public async updateItem(id: number, custo: NotasAgrupadasType): Promise<NotasAgrupadasType> {
        try {
            const response = await axios.put(`${this.API_URL}/${id}`, custo);
            return response.data;
        } catch (error) {
            throw new Error(`Erro ao atualizar Item: ${error}`);
        }
    }

    // Método para deletar um Item
    public async deleteItem(id: number): Promise<void> {
        try {
            await axios.delete(`${this.API_URL}/${id}`);
        } catch (error) {
            throw new Error(`Erro ao deletar Item: ${error}`);
        }
    }
}

export default new NotasAgrupadasMesService();