import React, { Component } from 'react';
import { Snackbar, Alert } from '@mui/material';


type SnackbarCustomProps = {
  open: boolean;
  duration?: number; // Usando '?' para indicar que é opcional
  onClose: () => void;
  text: string;
  color?: string; // Também opcional
};
class SnackbarCustom extends Component<SnackbarCustomProps> {
  componentDidUpdate(prevProps: SnackbarCustomProps) {
    // Se as props mudaram e o Snackbar deve ser aberto, garantimos que o onClose será chamado após a duração especificada.
    if (this.props.open && this.props.open !== prevProps.open) {
      setTimeout(() => {
        if (this.props.onClose) {
          this.props.onClose();
        }
      }, this.props.duration || 6000);
    }
  }

  render() {
    const { open, duration, onClose, text, color } = this.props;
        // Mapeando a prop 'color' para os valores aceitos por 'severity'
    const severity :any = ['error', 'warning', 'info', 'success'].includes(color || '') ? color : "info";


    return (
      <Snackbar
        open={open}
        autoHideDuration={duration || 6000}
        onClose={onClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={onClose} severity={severity} sx={{ width: '100%' }}>
          {text}
        </Alert>
      </Snackbar>
    );
  }
}

export default SnackbarCustom;


/*

onde estou usando? no scenes/form/index.jsx

COMO USAR?
//importar
import SnackbarCustom from "../../components/Snackbar"; // Ajuste o caminho conforme necessário

// definir o estado
const [snackbarInfo, setSnackbarInfo] = useState({
  open: false,
  text: "",
  color: "",
  duration: 2000,
});


//definir a função de exibição
const showSnackbar = (text, color, duration) => {
  // Fecha o Snackbar primeiro para garantir que possa ser reaberto com as mesmas informações
  setSnackbarInfo({ open: false });
  setTimeout(() => {
    setSnackbarInfo({
      open: true,
      text,
      color,
      duration,
    });
  }, 100); // Um breve delay para permitir a transição do estado
};

//definir a função de fechar
const handleCloseSnackbar = () => {
  setSnackbarInfo({ ...snackbarInfo, open: false });
};

//definir o snackbar dentro do html
<SnackbarCustom
  open={snackbarInfo.open}
  text={snackbarInfo.text}
  color={snackbarInfo.color}
  duration={snackbarInfo.duration}
  onClose={handleCloseSnackbar}
/>

// chamando
showSnackbar("Operação bem-sucedida!", "success", 3000);

*/
