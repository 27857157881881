// import axios from "axios";
import { CooperadosType } from "../models/Cooperados";
import { ENDPOINTS } from "../configs/apiConfigs"; // Ajuste o caminho conforme necessário

import axiosInstance from './axiosConfig'; // Ajuste o caminho conforme necessário

const axios = axiosInstance;

class CooperadosService {
    private API_URL = ENDPOINTS.cooperados;

    // Método para obter todos os Notas Agrupadas
    public async getAllItens(): Promise<CooperadosType[]> {
        try {
            const response = await axios.get(this.API_URL);
            const convertedResponse = response.data.map((item:CooperadosType)=> ({
                ...item,
                total_capital: Number(item.total_capital),
                total_notas: Number(item.total_notas),
            }));
            // console.log(response.data);
            
            return convertedResponse;
        } catch (error) {
            throw new Error(`Erro ao obter Notas Agrupadas: ${error}`);
        }
    }


    // Método CONTAR os itens
    public async countItens(): Promise<Number> {
        try {
            const response = await axios.get(this.API_URL);
            console.log("cooperados");
            console.log(response.data);
            
            return response.data.length;
        } catch (error) {
            throw new Error(`Erro ao obter Notas Agrupadas: ${error}`);
        }
    }

    // Método para obter um Item por ID
    public async getItemById(id: number): Promise<CooperadosType> {
        try {
            const response = await axios.get(`${this.API_URL}/${id}`);
            return response.data;
        } catch (error) {
            throw new Error(`Erro ao obter Item: ${error}`);
        }
    }
    // Método para obter um Item por ID
    public async getItemByEntidadeId(entidadeId: number): Promise<CooperadosType[]> {
        try {
            console.log(`${this.API_URL}/entidade/${entidadeId}`);
            
            const response = await axios.get(`${this.API_URL}/entidade/${entidadeId}`);
            return response.data;
        } catch (error) {
            throw new Error(`Erro ao obter Item: ${error}`);
        }
    }

    // Método para criar um novo Item
    public async createItem(item: CooperadosType): Promise<CooperadosType> {
        try {
            const response = await axios.post(this.API_URL, item);
            return response.data;
        } catch (error) {
            throw new Error(`Erro ao criar Item: ${error}`);
        }
    }

    // Método para atualizar um Item
    public async updateItem(id: number, custo: CooperadosType): Promise<CooperadosType> {
        try {
            const response = await axios.put(`${this.API_URL}/${id}`, custo);
            return response.data;
        } catch (error) {
            throw new Error(`Erro ao atualizar Item: ${error}`);
        }
    }

    // Método para deletar um Item
    public async deleteItem(id: number): Promise<void> {
        try {
            await axios.delete(`${this.API_URL}/${id}`);
        } catch (error) {
            throw new Error(`Erro ao deletar Item: ${error}`);
        }
    }
}

export default new CooperadosService();