import {
    Box, Button, Paper, Table, TableBody, TableCell, TableContainer,
    TableHead, TableRow, Typography, useTheme, TextField, Autocomplete,
} from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import NotasAgrupadasMesService from "../../services/notasAgrupadasMesService";
import { NotasAgrupadasType } from "../../models/NotasAgrupadas";
import React, { useEffect, useState, useRef } from "react";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import exportToExcel from "../../components/exportToExcel";
import { useAuth } from '../../context/useAuth';
import { allowedRoles } from "../../configs/apiConfigs";
import { ptBR } from "date-fns/locale";
import { format } from 'date-fns';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useReactToPrint } from 'react-to-print';
import './index.css'; // Importe o arquivo CSS
import textFieldStyles from "./helpers_index"
import StyledButton from "../../components/StyledButton";

interface NotasAgrupadasProps { }

const NotasAgrupadas: React.FC<NotasAgrupadasProps> = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { user } = useAuth();
    const [items, setItems] = useState<NotasAgrupadasType[]>([]);
    const [filteredItems, setFilteredItems] = useState<NotasAgrupadasType[]>([]);
    const [selectedDate, setSelectedDate] = React.useState<Date | null>(new Date());
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);

    //FILTROS 
    const [grupoFilter, setGrupoFilter] = useState<string>('');
    const [subgrupoFilter, setSubgrupoFilter] = useState<string>('');
    const [centroCustoFilter, setCentroCustoFilter] = useState<string>('');

    const printRef = useRef();


    const currentYear = new Date().getFullYear();
    const minDate = new Date(currentYear - 3, 0); // Janeiro do ano atual - 10
    const maxDate = new Date(currentYear, 11); // Dezembro do ano atual

    const uniqueGroups = Array.from(new Set(items.map(item => item.grupo_nota))).filter(Boolean);
    const uniqueSubgroups = Array.from(new Set(items.map(item => item.subgrupo))).filter(Boolean);
    const uniqueCentroCustos = Array.from(new Set(items.map(item => item.ds_centro_custo))).filter(Boolean);

    // Função para manipular a impressão
    const handlePrint = useReactToPrint({
        content: () => printRef.current ? printRef.current : null,
    });


    const handleDateChange = (date: Date | null, isStartDate: boolean) => {
        if (isStartDate) {
            setStartDate(date);
        } else {
            setEndDate(date);
        }
    };


    useEffect(() => {
        if (startDate && endDate) {
            const startYearMonth = startDate.getFullYear() * 100 + (startDate.getMonth() + 1);
            const endYearMonth = endDate.getFullYear() * 100 + (endDate.getMonth() + 1);

            let filtered = items.filter(item => {
                const itemYearMonth = item.ano_mes || 0;
                const matchesGrupo = grupoFilter ? item.grupo_nota?.toLowerCase().includes(grupoFilter.toLowerCase()) : true;
                const matchesSubgrupo = subgrupoFilter ? item.subgrupo?.toLowerCase().includes(subgrupoFilter.toLowerCase()) : true;
                const matchesCentroCusto = centroCustoFilter ? item.ds_centro_custo?.toLowerCase().includes(centroCustoFilter.toLowerCase()) : true;

                return itemYearMonth >= startYearMonth &&
                    itemYearMonth <= endYearMonth &&
                    matchesGrupo &&
                    matchesSubgrupo &&
                    matchesCentroCusto;
            });

            // Ordenar por subgrupo
            filtered.sort((a, b) => {
                const subgrupoA = a.subgrupo || ''; // Substituir undefined por string vazia
                const subgrupoB = b.subgrupo || ''; // Substituir undefined por string vazia
                if (subgrupoA < subgrupoB) return -1;
                if (subgrupoA > subgrupoB) return 1;
                return 0;
            });

            setFilteredItems(filtered);
        }
    }, [startDate, endDate, items, grupoFilter, subgrupoFilter, centroCustoFilter]);



    useEffect(() => {
        const fetchItens = async () => {
            try {
                let itens: NotasAgrupadasType[] | null = null;
                if (allowedRoles.includes(user?.role ?? '')) {
                    itens = await NotasAgrupadasMesService.getAllItens(user!);
                } else {
                    const entidadeId = user?.cd_entidade ?? 0;
                    itens = await NotasAgrupadasMesService.getItemByEntidadeId(entidadeId);
                }
                setItems(itens);
            } catch (error) {
                console.log("Erro ao buscar itens desdobrados: ", error);
            }
        };

        fetchItens();
    }, [user, allowedRoles]);



    useEffect(() => {
        const now = new Date();
        const yearMonth = now.getFullYear() * 100 + (now.getMonth() + 1);
        const initialFilteredItems = items.filter(item => item.ano_mes === yearMonth);
        setFilteredItems(initialFilteredItems);
    }, [items]);


    // Tipos ajustados para representar a estrutura de agrupamento
    interface CentroCusto {
        [centroCusto: string]: NotasAgrupadasType;
    }

    interface GrupoSubgrupo {
        [subgrupo: string]: CentroCusto;
    }

    interface Grupos {
        [grupo: string]: GrupoSubgrupo;
    }

    // Função para calcular o valor máximo de CentroCusto em cada grupo
    const calcularMaxPorGrupoCentroCusto = (items: NotasAgrupadasType[]): { [grupo: string]: { [centroCusto: string]: number } } => {
        const maxPorGrupoCentroCusto: { [grupo: string]: { [centroCusto: string]: number } } = {};

        items.forEach(item => {
            const grupo = item.grupo_nota;
            const centroCusto = item.ds_centro_custo;
            const vlCustoNumerico = Number(item.vl_custo) || 0;

            if (grupo && centroCusto) {
                if (!maxPorGrupoCentroCusto[grupo]) {
                    maxPorGrupoCentroCusto[grupo] = {};
                }
                if (!maxPorGrupoCentroCusto[grupo][centroCusto]) {
                    maxPorGrupoCentroCusto[grupo][centroCusto] = 0;
                }
                if (vlCustoNumerico > maxPorGrupoCentroCusto[grupo][centroCusto]) {
                    maxPorGrupoCentroCusto[grupo][centroCusto] = vlCustoNumerico;
                }
            }
        });

        return maxPorGrupoCentroCusto;
    };



    // Função para agrupar os itens por grupo, subgrupo e centro de custo
    const agruparPorGrupoSubgrupoCentroCusto = (items: NotasAgrupadasType[]): Grupos => {
        // Inicializa um objeto para armazenar os grupos, subgrupos e centros de custo
        const grupos: Grupos = {};

        // Calcula o valor máximo de CentroCusto em cada grupo
        let maxPorGrupoCentroCusto = calcularMaxPorGrupoCentroCusto(items);

        // Itera sobre cada item na lista de itens
        items.forEach(item => {
            // Extrai as propriedades de grupo, subgrupo e centro de custo do item
            const grupo = item.grupo_nota;
            const subgrupo = item.subgrupo;
            const centroCusto = item.ds_centro_custo;

            // Converte o valor de custo para um número, ou 0 se não for um número válido
            const vlCustoNumerico = Number(item.vl_custo) || 0;

            // Verifica se as propriedades grupo, subgrupo e centro de custo estão definidas
            if (grupo && subgrupo && centroCusto) {
                // Se o grupo ainda não existe no objeto grupos, cria uma nova entrada
                if (!grupos[grupo]) {
                    grupos[grupo] = {};
                }
                // Se o subgrupo ainda não existe dentro do grupo, cria uma nova entrada
                if (!grupos[grupo][subgrupo]) {
                    grupos[grupo][subgrupo] = {};
                }
                // Se o centro de custo ainda não existe dentro do subgrupo, cria uma nova entrada
                if (!grupos[grupo][subgrupo][centroCusto]) {
                    // Adiciona o item ao centro de custo com o valor de custo numérico e percentual inicializado para 0
                    grupos[grupo][subgrupo][centroCusto] = { ...item, vl_custo: vlCustoNumerico, percentual_do_total: 0 };
                } else {
                    // Se o centro de custo já existe, soma o valor de custo atual com o novo valor
                    const vlCustoAtual = Number(grupos[grupo][subgrupo][centroCusto].vl_custo) || 0;
                    grupos[grupo][subgrupo][centroCusto].vl_custo = vlCustoAtual + vlCustoNumerico;
                }
            }
        });

        // Calcula o percentual para cada item dentro do grupo, comparado ao valor máximo do centro de custo no mesmo grupo
        Object.keys(grupos).forEach(grupo => {
            Object.keys(grupos[grupo]).forEach(subgrupo => {
                Object.keys(grupos[grupo][subgrupo]).forEach(centroCusto => {
                    // Obtém o valor máximo de centro de custo para o grupo específico
                    const maxCentroCusto = maxPorGrupoCentroCusto[grupo][centroCusto] || 1; // Garante que não dividimos por zero
                    // Calcula o percentual do valor de custo atual em relação ao valor máximo
                    const item = grupos[grupo][subgrupo][centroCusto];
                    item.percentual_do_total = (Number(item.vl_custo) / maxCentroCusto) * 100;
                });
            });
        });

        // Retorna o objeto agrupado com os percentuais calculados
        return grupos;
    };




    const grupos = agruparPorGrupoSubgrupoCentroCusto(filteredItems);


    // Função para formatar a data no formato Mês/Ano brasileiro
    const formatDate = (date: Date | null): string => {
        if (!date) return '';
        return new Intl.DateTimeFormat('pt-BR', { month: 'long', year: 'numeric' }).format(date);
    };

    const SUBGROUPS_PER_ROW = 4;

    return (

        <>
            <style>
                {`
          @media print {
            body { background-color: white !important; }
            .print-container { color: black !important; }
            .print-container h3 { color: black !important; }
            .print-container .MuiTableCell-root { color: black !important; }
            .print-container .value { color: #2e7d32 !important; }
            .group-container {
              page-break-after: always;
              break-after: page;
            }
            .group-container:last-child {
              page-break-after: auto;
              break-after: auto;
            }
          }
        `}
            </style>

            <Box m="20px">


                <Header title="Custos das Notas" subtitle="Lista de Custos" />
                <Box mb="20px">


                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                        <DatePicker
                            views={['year', 'month']}
                            label="Data Inicial"
                            value={startDate}
                            onChange={(date) => handleDateChange(date, true)}
                            minDate={minDate}
                            maxDate={maxDate}
                            renderInput={(params) =>
                                <TextField sx={textFieldStyles}
                                    {...params} fullWidth />


                            }
                        />
                        <DatePicker
                            views={['year', 'month']}
                            label="Data Final"
                            value={endDate}
                            onChange={(date) => handleDateChange(date, false)}
                            minDate={minDate}
                            maxDate={maxDate}
                            renderInput={(params) =>
                                <TextField sx={textFieldStyles}
                                    {...params} fullWidth />
                            }
                        />
                    </LocalizationProvider>
                    <Box mb="20px">
                        <Autocomplete
                            options={uniqueGroups}
                            value={grupoFilter}
                            onChange={(event, newValue) => setGrupoFilter(newValue || "")}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Filtrar por Grupo"
                                    fullWidth
                                    sx={textFieldStyles}
                                />
                            )}
                        />
                        <Autocomplete
                            options={uniqueSubgroups}
                            value={subgrupoFilter}
                            onChange={(event, newValue) => setSubgrupoFilter(newValue || "")}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Filtrar por Subgrupo"
                                    fullWidth
                                    sx={textFieldStyles}
                                />
                            )}
                        />
                        <Autocomplete
                            options={uniqueCentroCustos}
                            value={centroCustoFilter}
                            onChange={(event, newValue) => setCentroCustoFilter(newValue || "")}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Filtrar por Centro de Custo"
                                    fullWidth
                                    sx={textFieldStyles}
                                />
                            )}
                        />

                    </Box>
                </Box>

                <StyledButton
                    onClick={() => handlePrint()}
                    colorVariant="gray">
                    <DownloadOutlinedIcon sx={{ mr: "10px" }} />

                    Imprimir
                </StyledButton>

                <Box
                    ref={printRef}
                    sx={{
                        marginLeft: "5px",
                    }}
                >

                    <h2 > RELATÓRIO COOPERATIVA </h2>
                    <h4 > Período inicial: {formatDate(startDate)} e Período final: {formatDate(endDate)}</h4>


                    <div className="print-container" style={{ display: 'flex', flexDirection: 'column', gap: '20px', background: colors.grey[900], color: 'green', padding: '20px' }}>
                        {Object.entries(grupos).map(([grupo, grupoData]) => (
                            <div key={grupo} className="group-container" style={{ marginBottom: '20px' }}>
                                <h3 style={{ marginBottom: '10px', fontSize: '1.2rem' }}>{grupo.toUpperCase()}</h3>
                                <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                                    {Object.keys(grupoData).reduce((acc: JSX.Element[][], subgrupo, index) => {
                                        if (index % SUBGROUPS_PER_ROW === 0) acc.push([]);
                                        acc[acc.length - 1].push(
                                            <div key={subgrupo} style={{ flex: 1, minWidth: '200px', marginBottom: '20px' }}>
                                                <Table style={{ borderCollapse: 'separate', borderSpacing: '0 4px' }}>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell style={{ color: 'green', fontWeight: 'bold', borderBottom: 'none', padding: '4px 16px' }}>
                                                                {subgrupo.toUpperCase()}
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {Object.entries(grupoData[subgrupo]).map(([centroCusto, data]) => (
                                                            <TableRow key={centroCusto}>
                                                                <TableCell style={{ borderBottom: 'none', padding: '2px 16px' }}>
                                                                    <div style={{ fontSize: '0.9rem' }}>{centroCusto}</div>
                                                                    <div className="value" style={{ color: '#4CAF50', fontWeight: 'bold', fontSize: '0.9rem' }}>
                                                                        {typeof data.vl_custo === 'number'
                                                                            ? new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(data.vl_custo)
                                                                            : 'R$ 0,00'}


                                                                        <span style={{ color: '#555555' }}>
                                                                            {' | '}
                                                                            {typeof data.percentual_do_total === 'number'
                                                                                ? new Intl.NumberFormat('pt-BR', { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(data.percentual_do_total / 100)
                                                                                : '0,00%'}
                                                                        </span>
                                                                    </div>
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </div>
                                        );
                                        return acc;
                                    }, []).map((row, rowIndex) => (
                                        <div key={rowIndex} style={{ display: 'flex', justifyContent: 'flex-start', gap: '20px' }}>
                                            {row}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>








                </Box>

            </Box>
        </>
    );
};

export default NotasAgrupadas;
