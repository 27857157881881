export type EstoqueType = {
    id?: number;
    cd_material?: number;
    ds_material?: string;
    nr_estoque_disponivel?: number;
    vl_custo_reposicao?: number;
    dt_cadastro?: Date;
    dt_atualizacao?: Date;
    dt_ultima_venda?: Date;
};


export type EstoqueInfosPage = {
    qtde_estoque?: number;
    data_ultima_venda?: Date;

}

export default function createEmptyEstoque(): EstoqueType  {
    return {
        id: undefined,
        cd_material: undefined,
        ds_material: "",
        nr_estoque_disponivel: undefined,
        vl_custo_reposicao: undefined,
        dt_cadastro: undefined,
        dt_atualizacao: undefined,
        dt_ultima_venda: undefined,
    };
}