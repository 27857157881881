import React, { useEffect, useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, InputLabel, MenuItem, Select, TextField, ThemeProvider, Typography, useTheme } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
// import { mockData } from "../../data/mockData";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import Header from "../../components/Header";
import { GridColDef, GridAlignment } from '@mui/x-data-grid';
import createEmptyUser, { UserType } from '../../models/User';
import userGestaoService from '../../services/userGestaoService';
import { ptBR } from '@mui/x-data-grid/locales';
import { useAuth } from '../../context/useAuth';
import { useNavigate } from 'react-router-dom';
import { allowedRoles } from '../../configs/apiConfigs';
import { z } from 'zod';
// import { Controller, useForm } from 'react-hook-form';
// import { zodResolver } from '@hookform/resolvers/zod';
import * as Yup from 'yup'; // Para validação
import { Form, Formik } from 'formik';
import PasswordForm from '../../components/formularios/PasswordForm';
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import diretoriaService from '../../services/diretoriaService';
import { DiretoriaType } from '../../models/Diretoria';
import { dataGridHeaderStyles } from "../../components/styles/dataGridStyles"; // Importe o objeto de estilos
import StyledButton from '../../components/StyledButton';

interface rowCellProps {
  access: string
}




const Usuarios = () => {
  const navigate = useNavigate();

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [usuarios, setUsuarios] = useState<UserType[]>([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogPassword, setOpenDialogPassword] = useState(false);
  const [editableUser, setEditableUser] = useState<UserType | null>(null);
  const [diretorias, setDiretorias] = useState<DiretoriaType[] | null>(null);
  const [reload, setReload] = useState(false); // Estado para controlar o recarregamento dos dados
  const [isCreating, setIsCreating] = useState(false);
  const [productOptions, setProductOptions] = useState<{ value: number, label: string }[]>([]);

  const { user } = useAuth();

  const userSchema = Yup.object().shape({
    name: Yup.string().required('Nome é obrigatório'),
    cpf_cnpj: Yup.string().required('CPF/CNPJ é obrigatório'),
    email: Yup.string().email('Email inválido').required('Email é obrigatório'),
    nr_celular: Yup.string().required('Número de celular é obrigatório'),
    // password: isCreating ? Yup.string().min(6, 'A senha deve ter pelo menos 6 caracteres').required('Senha é obrigatória') : null,
    role: Yup.string().required('Permissão é obrigatória'),
    diretoria: Yup.string()
  });

  useEffect(() => {

    const fechtUsuarios = async () => {

      if (!allowedRoles.includes(user?.role ?? '')) {
        // Redireciona para a página inicial ou de login se não tiver o role adequado
        navigate('/admin');
      }
      try {

        const usuarios = await userGestaoService.getAllUsers();
        setUsuarios(usuarios);

        const diretorias = await diretoriaService.getAllDiretorias();
        setDiretorias(diretorias);


        console.log("Diretorias", diretorias);

        // Mapeie as diretorias para o formato necessário para o Select
        const options = diretorias.map((diretoria: DiretoriaType) => ({
          value: diretoria.id ?? 0, // Aqui estou usando o ID como valor
          label: diretoria.nome, // O nome da diretoria como label
        }));

        setProductOptions(options);

      } catch (error) {
        console.log("Erro ao buscar o usuários", error);
      }


    };


    fechtUsuarios();
  }, [reload]);



  const toastMessage = (mensagem: string, type: string = "success") => {
    if (type == "success") {
      toast.success(mensagem, {
        position: "top-center"
      });
    } else {
      toast.error(mensagem, {
        position: "top-center"
      });

    }


  }
  const handleOpenDialogEdit = (user: UserType) => {
    setEditableUser(user);
    setIsCreating(false);
    setOpenDialog(true);

  };


  //criação de um novo usuário
  const handleOpenDialogCreate = () => {
    // Configurar um usuário vazio para criação
    setEditableUser(createEmptyUser);
    setIsCreating(true);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };


  const passwordInitialValues = {
    name: editableUser?.name || "",
    email: editableUser?.email || "",
    senha1: '',
    senha2: ''
  };


  // editar a senha do usuário
  const handleOpenDialogPassword = (user: UserType) => {
    setEditableUser(user);
    setOpenDialogPassword(true);

  };
  const handleCloseDialogPassword = () => {
    setOpenDialogPassword(false);
  };



  const onSubmit = async (data: UserType) => {
    // Aqui você pode adicionar a lógica para salvar as alterações no servidor
    console.log('Salvando alterações para o usuário:', data);
    if (data) {
      try {

        if (isCreating) {
          // Implementar lógica de criação
          await userGestaoService.createUsuario(data);
          toastMessage("Usuário criado com sucesso!")

        } else {
          // Lógica de atualização
          await userGestaoService.updateUsuario(data, data.id);

          toastMessage("Usuário Editado com Sucesso!")


        }
        setReload(!reload);
      } catch (error) {
        toastMessage("Erro!" + error, "erro")

        console.log("Erro ao buscar o usuários", error);

      }


    }



    setOpenDialog(false);
  };

  const getDiretorias = () => {

  }

  // const productOptions = [
  //   { value: 'hortifruti', label: 'Hortifruti' },
  //   { value: 'insumos', label: 'Insumos' },
  //   // { value: 'composto', label: 'Composto' },
  //   // { value: 'composto_especial', label: 'Composto Especial' },
  //   // { value: 'composto_especial', label: 'Composto Especial' },
  //   // Adicione mais produtos conforme necessário
  // ];


  const permissionOptions = [
    { value: 'admin_master', label: 'Administrador' },
    { value: 'presidente', label: 'Presidente' },
    { value: 'diretor', label: 'Diretor' },
    { value: 'cooperado', label: 'Cooperado' },
  ];



  const columns: GridColDef[] = [
    // { field: "id", headerName: "ID" },
    { field: "cd_entidade", headerName: "CD Entidade", minWidth: 100, },
    {
      field: "name", headerName: "Nome", flex: 1.5, minWidth: 100,
    },
    {
      field: "cpf_cnpj", headerName: "CPF/CNPJ", type: "string", align: "left", minWidth: 100, flex: 1.2
    },
    {
      field: "email", headerName: "Email", flex: 2, minWidth: 100,
    },
    {
      field: "nr_celular", headerName: "Celular", flex: 1, minWidth: 100,
    },
    {
      field: "btn_editar",
      headerName: "Editar", minWidth: 100,
      flex: 1.5,
      renderCell: ({ row }: { row: rowCellProps }) => {
        // const { access } = row;
        return (
          <div>
            <Box
              display="flex" // Adiciona o display flex para alinhar os filhos lado a lado
              width="100%" // Define a largura total do container
            >
              {/* Box para o botão Editar */}
              <Box
                width="50%" // Cada Box ocupa 50% do espaço
                sx={{

                  backgroundColor: colors.greenAccent[700], // Cor de fundo
                  borderRadius: "4px", // Bordas arredondadas
                  display: "flex", // Utiliza flex para centralizar o conteúdo
                  justifyContent: "center", // Centraliza o conteúdo horizontalmente
                  alignItems: "center" // Centraliza o conteúdo verticalmente
                }}
              >
                <Button sx={{ color: "white" }} onClick={() => handleOpenDialogEdit(row as UserType)}>Editar</Button>



              </Box>

              {/* Box para o botão Senha */}
              <Box
                width="50%" // Cada Box ocupa 50% do espaço
                marginLeft={"3px"}
                sx={{

                  backgroundColor: colors.redAccent[500], // Cor de fundo
                  borderRadius: "4px", // Bordas arredondadas
                  display: "flex", // Utiliza flex para centralizar o conteúdo
                  justifyContent: "center", // Centraliza o conteúdo horizontalmente
                  alignItems: "center" // Centraliza o conteúdo verticalmente
                }}
              >
                <Button
                  onClick={() => handleOpenDialogPassword(row as UserType)}
                  sx={{ color: 'white' }} // Cor do texto do botão Senha
                >
                  Senha
                </Button>
              </Box>
            </Box>
          </div>
        );
      },
    },
  ];

  return (
    <Box m="20px">
      <ThemeProvider theme={theme}>

        <Header title="USUÁRIOS" subtitle="Gestão de Usuários" />




        <StyledButton
          onClick={handleOpenDialogCreate}
          colorVariant="gray">

          Criar novo Usuário
        </StyledButton>

        <Box
          m="40px 0 0 0"
          sx={{
            ...dataGridHeaderStyles, // Aplica os estilos reutilizáveis
            width: '100%',
            height: '75vh',
            overflowX: 'auto', // Adiciona rolagem horizontal
          }}
        >
          <DataGrid
            componentsProps={{
              toolbar: {
                printOptions: { disableToolbarButton: true },
                csvOptions: { disableToolbarButton: true },
                sx: {
                  '& .MuiButton-root': { // Estiliza todos os botões na barra de ferramentas
                    color: 'white', backgroundColor: colors.greenAccent[300], '&:hover': {
                      backgroundColor: 'darkblue',
                    },
                    "m": "5px"
                  },
                },
              },

            }}
            localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}

            rows={usuarios}
            columns={columns}

            components={{
              Toolbar: GridToolbar,
            }}

          />


          <Dialog open={openDialog} onClose={handleCloseDialog}>
            <DialogTitle sx={{ backgroundColor: colors.greenAccent[300] }} >

              {isCreating ? "Criar Novo Usuário" : "Editar Usuário"}


            </DialogTitle>
            <Formik
              initialValues={
                editableUser || createEmptyUser()
              }
              validationSchema={userSchema}
              onSubmit={(values: UserType) => {
                onSubmit(values);
              }}
              enableReinitialize
            >
              {({ values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit, }) => (

                <Form>
                  <DialogContent sx={{ backgroundColor: colors.primary[400], color: colors.primary[500] }}>
                    <TextField
                      name="name"
                      autoFocus
                      margin="dense"
                      label="Nome"
                      type="text"
                      fullWidth
                      variant="outlined"
                      value={values.name}
                      onChange={handleChange}
                      error={touched.name && Boolean(errors.name)}
                      helperText={touched.name && errors.name}
                    />
                    <TextField
                      autoFocus
                      name="cpf_cnpj"
                      margin="dense"
                      label="CPF/CNPJ"
                      type="text"
                      fullWidth
                      variant="outlined"
                      value={values.cpf_cnpj}
                      onChange={handleChange}
                      sx={{ input: { color: colors.grey[100] } }}
                      error={touched.cpf_cnpj && Boolean(errors.cpf_cnpj)}
                      helperText={touched.cpf_cnpj && errors.cpf_cnpj}
                    />
                    <TextField
                      name="email"
                      margin="dense"
                      label="Email"
                      type="email"
                      fullWidth
                      variant="outlined"
                      value={values.email}
                      onChange={handleChange}
                      error={touched.email && Boolean(errors.email)}
                      helperText={touched.email && errors.email}
                      sx={{ input: { color: colors.grey[100] } }}
                    />
                    <TextField
                      name="nr_celular"
                      margin="dense"
                      label="Celular"
                      type="text"
                      fullWidth
                      variant="outlined"
                      value={values.nr_celular || ''}
                      onChange={handleChange}
                      error={touched.nr_celular && Boolean(errors.nr_celular)}
                      helperText={touched.nr_celular && errors.nr_celular}
                      sx={{ input: { color: colors.grey[100] } }}
                    />

                    <InputLabel id="role-select-label">Permissão no Sistema</InputLabel>
                    <Select
                      labelId="role-select-label"
                      id="role-select"
                      name="role"
                      value={values.role || ''}
                      label="Permissão no Sistema"
                      onChange={handleChange}
                      error={touched.role && Boolean(errors.role)}
                      sx={{ color: colors.grey[100], '& .MuiOutlinedInput-notchedOutline': { borderColor: colors.grey[300] } }}
                    >
                      {permissionOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>

                    {values.role === 'diretor' && (
                      <>
                        <InputLabel id="product-director-select-label">Produto</InputLabel>
                        <Select
                          labelId="product-director-select-label"
                          id="product-director-select"
                          name="diretoria"
                          value={values.diretoria || ''}
                          label="Produto"
                          onChange={handleChange}
                          error={touched.diretoria && Boolean(errors.diretoria)}
                          sx={{ color: colors.grey[100], '& .MuiOutlinedInput-notchedOutline': { borderColor: colors.grey[300] } }}
                        >
                          {productOptions.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </>
                    )}
                  </DialogContent>
                  <DialogActions sx={{ backgroundColor: colors.greenAccent[300] }} >
                    <Button onClick={handleCloseDialog} sx={{ color: colors.greenAccent[200] }}>Cancelar</Button>
                    <Button type="submit" sx={{ color: colors.greenAccent[200] }}>Salvar</Button>
                  </DialogActions>
                </Form>
              )}
            </Formik>

          </Dialog>


          <Dialog open={openDialogPassword} onClose={handleOpenDialogPassword} sx={{
            '& .MuiDialog-paper': { // Acessa o papel do diálogo diretamente
              width: '50%', // Define a largura para 50% da largura da viewport
              maxWidth: 'none', // Remove qualquer limitação de largura máxima
            }
          }}>
            <DialogTitle sx={{ backgroundColor: colors.greenAccent[300] }} >
              Edição de senha
            </DialogTitle>
            <DialogContent sx={{ backgroundColor: colors.primary[400], color: colors.primary[500], }}>
              <Box sx={{ marginBottom: "15px" }} ></Box>
              <PasswordForm initialValues={passwordInitialValues} isNonMobile={true} handleCloseDialog={handleCloseDialogPassword} />
            </DialogContent>

          </Dialog>


        </Box>
      </ThemeProvider>

      <ToastContainer />

    </Box>
  );
};

export default Usuarios;
