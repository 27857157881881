import React, { useEffect, useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, ThemeProvider, Typography, useMediaQuery, useTheme } from "@mui/material";
import { DataGrid, GridToolbar, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import createEmptyEstoque, { EstoqueType, EstoqueInfosPage } from '../../models/Estoque';
import estoqueService from '../../services/estoqueService';
import { ptBR } from '@mui/x-data-grid/locales';
import { useAuth } from '../../context/useAuth';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { formatCurrency, formatDate } from '../../configs/helpers';
import StatBox from '../../components/StatBox';
import AutoAwesomeMotion from '@mui/icons-material/AutoAwesomeMotion';
import CustomCard from '../../components/CustomCard';
import exportToExcel from '../../components/exportToExcel';
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import { dataGridHeaderStyles } from "../../components/styles/dataGridStyles"; // Importe o objeto de estilos
import StyledButton from '../../components/StyledButton';

const EstoquePage = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [estoque, setEstoque] = useState<EstoqueType[]>([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [editableItem, setEditableItem] = useState<EstoqueType | null>(null);
  const [reload, setReload] = useState(false); // Estado para controlar o recarregamento dos dados
  const [isCreating, setIsCreating] = useState(false);
  const [estoqueInfo, setEstoqueInfo] = useState<EstoqueInfosPage>();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const { user } = useAuth();

  const estoqueSchema = Yup.object().shape({
    cd_material: Yup.number().required('Código do material é obrigatório'),
    ds_material: Yup.string().required('Descrição do material é obrigatória'),
    nr_estoque_disponivel: Yup.number().required('Estoque disponível é obrigatório'),
    vl_custo_reposicao: Yup.number().required('Custo de reposição é obrigatório'),
  });


  const retornarUltimaData = (estoque_passado: EstoqueType[]) => {
    const latestDate = estoque
      .map(item => item.dt_ultima_venda)
      .filter((date): date is Date => date !== undefined && date !== null) // Remove datas indefinidas ou nulas
      .reduce((latest, current) => {
        return latest > current ? latest : current;
      }, new Date(0)); // Inicializa com a data mínima
    return latestDate;
  }
  useEffect(() => {
    const fetchEstoque = async () => {
      try {
        const estoque = await estoqueService.getAllItens();
        const estoqueInfo_: EstoqueInfosPage = {
          data_ultima_venda: retornarUltimaData(estoque),
          qtde_estoque: estoque.length,
        }
        setEstoqueInfo(estoqueInfo_);
        setEstoque(estoque);
      } catch (error) {
        console.log("Erro ao buscar o estoque", error);
      }
    };

    fetchEstoque();
  }, [reload]);

  const toastMessage = (mensagem: string, type: string = "success") => {
    if (type === "success") {
      toast.success(mensagem, {
        position: "top-center"
      });
    } else {
      toast.error(mensagem, {
        position: "top-center"
      });
    }
  };

  const handleOpenDialogEdit = (item: EstoqueType) => {
    setEditableItem(item);
    setIsCreating(false);
    setOpenDialog(true);
  };

  const handleOpenDialogCreate = () => {
    setEditableItem(createEmptyEstoque);
    setIsCreating(true);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const onSubmit = async (data: EstoqueType) => {
    if (data) {
      try {
        if (isCreating) {
          await estoqueService.createItem(data);
          toastMessage("Item de estoque criado com sucesso!");
        } else {
          await estoqueService.updateItem(data.id || 0, data);
          toastMessage("Item de estoque atualizado com sucesso!");
        }
        setReload(!reload);
      } catch (error) {
        toastMessage("Erro!" + error, "error");
        console.log("Erro ao salvar o item", error);
      }
    }
    setOpenDialog(false);
  };

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID" },
    { field: "cd_material", headerName: "Código Material", flex: 1, minWidth: 100 },
    { field: "ds_material", headerName: "Descrição", flex: 2, minWidth: 100 },
    { field: "nr_estoque_disponivel", headerName: "Estoque Disponível", type: "number", flex: 1, minWidth: 100 },
    {
      field: "vl_custo_reposicao", headerName: "Custo de Reposição", type: "number", flex: 1, minWidth: 100,

      renderCell: (params: GridRenderCellParams<number>) => (
        <Typography color={colors.greenAccent[500]}>
          {formatCurrency(params.row.vl_custo_reposicao)}
          {/* {new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL',
            }).format(params.row.vl_funrural)} */}
        </Typography>
      ),
    },


    {
      field: "dt_cadastro",
      headerName: "Data do cadastro",
      flex: 1,
      minWidth: 100,
      renderCell: (params: GridRenderCellParams<Date>) => {
        const dateValue = params.row.dt_cadastro;
        return (
          <Typography color={colors.greenAccent[500]}>
            {dateValue ? new Intl.DateTimeFormat('pt-BR', {
              dateStyle: 'medium'
            }).format(new Date(dateValue)) : ''}
          </Typography>
        );
      }
    },
    {
      field: "dt_atualizacao",
      headerName: "Data atualização",
      flex: 1,
      minWidth: 100,

      renderCell: (params: GridRenderCellParams<Date>) => {
        const dateValue = params.row.dt_atualizacao;
        return (
          <Typography color={colors.greenAccent[500]}>
            {dateValue ? new Intl.DateTimeFormat('pt-BR', {
              dateStyle: 'medium'
            }).format(new Date(dateValue)) : ''}
          </Typography>
        );
      }
    },
    {
      field: "dt_ultima_venda",
      headerName: "Data última venda",
      flex: 1,
      minWidth: 100,

      renderCell: (params: GridRenderCellParams<Date>) => {
        const dateValue = params.row.dt_ultima_venda;
        return (
          <Typography color={colors.greenAccent[500]}>
            {dateValue ? new Intl.DateTimeFormat('pt-BR', {
              dateStyle: 'medium'
            }).format(new Date(dateValue)) : ''}
          </Typography>
        );
      }
    },
  ];

  return (
    <Box m="20px">
      <ThemeProvider theme={theme}>
        <Header title="ESTOQUE" subtitle="Gestão de Estoque" />



        <Box
          display="grid"
          gridTemplateColumns="repeat(12, 1fr)"
          gridAutoRows="140px"
          gap="20px"
          marginBottom={"10px;"}
        >

          <Box
            gridColumn={isMobile ? "span 6" : "span 4"}
            sx={{ backgroundColor: colors.primary[400] }}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <CustomCard
              title={`${estoqueInfo?.qtde_estoque}`}
              subtitle="Total de produtos"
              icon={
                <AutoAwesomeMotion
                  sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                />
              }
            />
          </Box>

        </Box>


        <StyledButton
          onClick={() => exportToExcel(estoque, "Estoque")}
          colorVariant="gray">
          <DownloadOutlinedIcon sx={{ mr: "10px" }} />

          Baixar em Excel
        </StyledButton>
        <Box
          height="100vh"
          sx={{
            ...dataGridHeaderStyles, // Aplica os estilos reutilizáveis
            width: '100%',
            height: '75vh',
            overflowX: 'auto', // Adiciona rolagem horizontal
          }}
        >
          <DataGrid
            componentsProps={{
              toolbar: {
                printOptions: { disableToolbarButton: true },
                csvOptions: { disableToolbarButton: true },
                sx: {
                  '& .MuiButton-root': {
                    color: 'white', backgroundColor: colors.greenAccent[300], '&:hover': {
                      backgroundColor: 'darkblue',
                    },
                    m: "5px"
                  },
                },
              },
            }}
            localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
            rows={estoque}
            columns={columns}
            components={{
              Toolbar: GridToolbar,
            }}
          />

          <Dialog open={openDialog} onClose={handleCloseDialog}>
            <DialogTitle sx={{ backgroundColor: colors.greenAccent[300] }}>
              {isCreating ? "Criar Novo Item" : "Editar Item"}
            </DialogTitle>
            <Formik
              initialValues={
                editableItem || createEmptyEstoque()
              }
              validationSchema={estoqueSchema}
              onSubmit={(values: EstoqueType) => {
                onSubmit(values);
              }}
              enableReinitialize
            >
              {({ values, errors, touched, handleChange, handleSubmit }) => (
                <Form>
                  <DialogContent sx={{ backgroundColor: colors.primary[400], color: colors.primary[500] }}>
                    <TextField
                      name="cd_material"
                      autoFocus
                      margin="dense"
                      label="Código Material"
                      type="number"
                      fullWidth
                      variant="outlined"
                      value={values.cd_material}
                      onChange={handleChange}
                      error={touched.cd_material && Boolean(errors.cd_material)}
                      helperText={touched.cd_material && errors.cd_material}
                    />
                    <TextField
                      name="ds_material"
                      margin="dense"
                      label="Descrição"
                      type="text"
                      fullWidth
                      variant="outlined"
                      value={values.ds_material}
                      onChange={handleChange}
                      error={touched.ds_material && Boolean(errors.ds_material)}
                      helperText={touched.ds_material && errors.ds_material}
                      sx={{ input: { color: colors.grey[100] } }}
                    />
                    <TextField
                      name="nr_estoque_disponivel"
                      margin="dense"
                      label="Estoque Disponível"
                      type="number"
                      fullWidth
                      variant="outlined"
                      value={values.nr_estoque_disponivel}
                      onChange={handleChange}
                      error={touched.nr_estoque_disponivel && Boolean(errors.nr_estoque_disponivel)}
                      helperText={touched.nr_estoque_disponivel && errors.nr_estoque_disponivel}
                      sx={{ input: { color: colors.grey[100] } }}
                    />
                    <TextField
                      name="vl_custo_reposicao"
                      margin="dense"
                      label="Custo de Reposição"
                      type="number"
                      fullWidth
                      variant="outlined"
                      value={values.vl_custo_reposicao}
                      onChange={handleChange}
                      error={touched.vl_custo_reposicao && Boolean(errors.vl_custo_reposicao)}
                      helperText={touched.vl_custo_reposicao && errors.vl_custo_reposicao}
                      sx={{ input: { color: colors.grey[100] } }}
                    />
                  </DialogContent>
                  <DialogActions sx={{ backgroundColor: colors.greenAccent[300] }}>
                    <Button onClick={handleCloseDialog} sx={{ color: colors.greenAccent[200] }}>Cancelar</Button>
                    <Button type="submit" sx={{ color: colors.greenAccent[200] }}>Salvar</Button>
                  </DialogActions>
                </Form>
              )}
            </Formik>
          </Dialog>
        </Box>
      </ThemeProvider>
      <ToastContainer />
    </Box>
  );
};

export default EstoquePage;
