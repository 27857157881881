// import axios from "axios";
import { ContaMovimentoNotasType } from "../models/ContaMovimentoNotas";
import { ENDPOINTS } from "../configs/apiConfigs"; // Ajuste o caminho conforme necessário
import axiosInstance from './axiosConfig'; // Ajuste o caminho conforme necessário

const axios = axiosInstance;

class ContaMovimentoNotasService {
    private API_URL = ENDPOINTS.conta_movimento_notas;

    public sortValues(data:ContaMovimentoNotasType[]) : ContaMovimentoNotasType[]{
        const sortedData = data.sort((a: ContaMovimentoNotasType, b: ContaMovimentoNotasType) => {
            return (a.numero_nf || 0) - (b.numero_nf || 0); // Ordena de forma ascendente
        });
        return sortedData;
    }


    // Método para obter todas as notas de movimento de conta
    public async getAllItens(): Promise<ContaMovimentoNotasType[]> {
        try {
            const response = await axios.get(this.API_URL);
            console.log(response.data);

            const sortedData = this.sortValues(response.data)
            // return sortedData;
            return sortedData;
        } catch (error) {
            throw new Error(`Erro ao obter notas de movimento de conta: ${error}`);
        }
    }

    // Método para obter notas de movimento de conta por entidade (cd_entidade)
    public async getItemByCdEntidade(cd_entidade: number): Promise<ContaMovimentoNotasType[]> {
        try {
            const response = await axios.get(`${this.API_URL}/${cd_entidade}`);

            const sortedData = this.sortValues(response.data)

            return sortedData;
        } catch (error) {
            throw new Error(`Erro ao obter notas de movimento de conta por entidade: ${error}`);
        }
    }

    // Método para obter uma nota de movimento de conta por ID
    public async getItemById(id: number): Promise<ContaMovimentoNotasType> {
        try {
            const response = await axios.get(`${this.API_URL}/${id}`);
            return response.data;
        } catch (error) {
            throw new Error(`Erro ao obter nota de movimento de conta: ${error}`);
        }
    }

    // Método para criar uma nova nota de movimento de conta
    public async createItem(nota: ContaMovimentoNotasType): Promise<ContaMovimentoNotasType> {
        try {
            const response = await axios.post(this.API_URL, nota);
            return response.data;
        } catch (error) {
            throw new Error(`Erro ao criar nota de movimento de conta: ${error}`);
        }
    }

    // Método para criar notas de movimento de conta em lote
    public async createItemsBatch(notas: ContaMovimentoNotasType[]): Promise<ContaMovimentoNotasType[]> {
        try {
            const response = await axios.post(`${this.API_URL}/lote`, notas);
            return response.data;
        } catch (error) {
            throw new Error(`Erro ao criar notas de movimento de conta em lote: ${error}`);
        }
    }

    // Método para atualizar uma nota de movimento de conta
    public async updateItem(id: number, nota: ContaMovimentoNotasType): Promise<ContaMovimentoNotasType> {
        try {
            const response = await axios.put(`${this.API_URL}/${id}`, nota);
            return response.data;
        } catch (error) {
            throw new Error(`Erro ao atualizar nota de movimento de conta: ${error}`);
        }
    }

    // Método para deletar uma nota de movimento de conta
    public async deleteItem(id: number): Promise<void> {
        try {
            await axios.delete(`${this.API_URL}/${id}`);
        } catch (error) {
            throw new Error(`Erro ao deletar nota de movimento de conta: ${error}`);
        }
    }

    // Método para deletar todas as notas de movimento de conta
    public async deleteAllData(hash_client: string): Promise<void> {
        try {
            await axios.delete(`${this.API_URL}/destroy_all_data/${hash_client}`);
        } catch (error) {
            throw new Error(`Erro ao deletar todas as notas de movimento de conta: ${error}`);
        }
    }
}

export default new ContaMovimentoNotasService();
