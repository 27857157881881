// import axios from "axios";
import { CustosDesdobradosType } from "../models/CustosDesdobrados";
import { ENDPOINTS } from "../configs/apiConfigs"; // Ajuste o caminho conforme necessário
import notasAgrupadasMesService from "./notasAgrupadasMesService";
import { NotasAgrupadasType } from "../models/NotasAgrupadas";

import axiosInstance from './axiosConfig'; // Ajuste o caminho conforme necessário
import { UserProfile } from "../models/User";

const axios = axiosInstance;



class dashBoardService {

    //quero trazer total de notas emitidas | quantidade | valor
    //total de cooperados 
    // taxa administrativa recebida
    // movimentação por grupo -> notasAgrupadasMesService


    


    public async getMovimentacaoPorGrupo(user:UserProfile) {

        const nota_agrupadas = await notasAgrupadasMesService.getAllItens(user);

        let nota_tratradas = this.groupAndSumByFields(nota_agrupadas, ["ano_mes", "subgrupo","ds_centro_custo"], "vl_custo");

        nota_tratradas = nota_tratradas.filter(item => item.ds_centro_custo === "MOVIMENTAÇÃO PRODUTO COOPERADO");

        const excludedSubgrupos = ["1_HORTIFRUTI", "1_INSUMOS"];

        nota_tratradas = nota_tratradas.filter(item => !excludedSubgrupos.includes(item.subgrupo));

        // console.log(notas);
        

        return nota_tratradas;

    }


    // Função que agrupa e soma os valores por uma chave especificada
    private groupAndSumByFields = (
        array: NotasAgrupadasType[],
        groupFields: (keyof NotasAgrupadasType)[],
        valueField: keyof NotasAgrupadasType
    ): any[] => {
        const resultMap = new Map<string, any>();

        array.forEach(item => {
            // Cria uma chave única para cada combinação de campos de agrupamento
            const groupKey = groupFields.map(field => item[field]).join('-');

            if (resultMap.has(groupKey)) {
                // Se a chave já existe no mapa, soma o valor
                resultMap.get(groupKey)!.totalVlCusto += Number(item[valueField]);
            } else {
                // Se a chave não existe, adiciona um novo item ao mapa
                const newItem: any = {
                    ano_mes: item.ano_mes,
                    subgrupo: item.subgrupo,
                    ds_centro_custo: item.ds_centro_custo,
                    vl_custo: Number(item[valueField])

                };
                resultMap.set(groupKey, newItem);
            }
        });

        // Converte o mapa de resultados de volta para um array
        return Array.from(resultMap.values());
    };




}

export default dashBoardService;