import { NotasFaturamentoType, TotaisPorGrupoSubGrupoType } from "../../models/NotasFaturamento";

export const somarPorGrupo = (notas: NotasFaturamentoType[]): TotaisPorGrupoSubGrupoType[] => {
    const totalPorGrupo: { [key: string]: number } = {};
    const totalPorSubgrupo: { [key: string]: number } = {};

    notas.forEach((nota) => {
        if (nota.grupo) {
            if (!totalPorGrupo[nota.grupo]) {
                totalPorGrupo[nota.grupo] = 0;
            }
            totalPorGrupo[nota.grupo] += Number(nota.vl_total) || 0;
        }

        if (nota.subgrupo) {
            if (!totalPorSubgrupo[nota.subgrupo]) {
                totalPorSubgrupo[nota.subgrupo] = 0;
            }
            totalPorSubgrupo[nota.subgrupo] += Number(nota.vl_total) || 0;
        }
    });

    const result: TotaisPorGrupoSubGrupoType[] = [];

    for (const grupo in totalPorGrupo) {
        result.push({
            grupo,
            subgrupo: '',
            totalGrupo: totalPorGrupo[grupo],
            totalSubGrupo: 0,
        });
    }

    for (const subgrupo in totalPorSubgrupo) {
        const grupo = notas.find((nota) => nota.subgrupo === subgrupo)?.grupo || '';
        const existingGrupo = result.find((item) => item.grupo === grupo);
        if (existingGrupo) {
            result.push({
                grupo: existingGrupo.grupo,
                subgrupo,
                totalGrupo: 0,
                totalSubGrupo: totalPorSubgrupo[subgrupo],
            });
        } else {
            result.push({
                grupo,
                subgrupo,
                totalGrupo: 0,
                totalSubGrupo: totalPorSubgrupo[subgrupo],
            });
        }
    }
    // console.log(result.filter((item) => item.subgrupo && item.subgrupo.trim() !== ''));
    const finalResult = result.filter((item) => item.subgrupo && item.subgrupo.trim() !== '')
    return finalResult;
};
