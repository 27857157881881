import { Box, Button, TextField } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import { useAuth } from "../../context/useAuth";
import { Snackbar } from "@mui/material";
import { Backdrop, CircularProgress } from "@mui/material";
import { updateUser } from "../../services/userService";

import React from "react";
import SnackbarCustom from "../../components/Snackbar"; // Ajuste o caminho conforme necessário

const Form = () => {
  const { user } = useAuth();
  const [loading, setLoading] = React.useState(false);

  const [snackbarInfo, setSnackbarInfo] = React.useState({
    open: false,
    text: "",
    color: "",
    duration: 2000,
  });

  const handleCloseSnackbar = () => {
    setSnackbarInfo({ ...snackbarInfo, open: false });
  };
  const isNonMobile = useMediaQuery("(min-width:600px)");

  // Definindo valores iniciais, incluindo nomeUsuario
  const initialValues = {
    name: user?.name,
    email: user?.email,
    senha1: "",
    senha2: "",
  };

  const handleFormSubmit = async (values:any) => {
    // console.log(values);

    // console.log(values.senha1)
    if (values.senha1 == values.senha2) {
      setLoading(true);

      try {
        const { success, error } = await updateUser(
          values.email,
          values.name,
          values.senha1
        );

        if (success) {
          setSnackbarInfo({
            open: true,
            text: "Operação bem-sucedida!",
            color: "success",
            duration: 2000,
          });
        } else {
          setSnackbarInfo({
            open: true,
            text: "errorMessage", // Display the error message from updateUser
            color: "error",
            duration: 2000,
          });
        }
      } catch (error) {
        console.error("Error updating user:", error);
        setSnackbarInfo({
          open: true,
          text: "Erro ao atualizar usuário",
          color: "error",
          duration: 2000,
        });
      } finally {
        setLoading(false);
      }
    } else {
      setSnackbarInfo({
        open: true,
        text: "Senha incorreta",
        color: "error", // 'error', 'warning', 'info', 'success'
        duration: 2000,
      });
    }
  };


  return (
    <Box m="20px">
      {snackbarInfo.open && (
        <SnackbarCustom
          open={snackbarInfo.open}
          text={snackbarInfo.text}
          color={snackbarInfo.color}
          duration={snackbarInfo.duration}
          onClose={handleCloseSnackbar}
        />
      )}

      <Header title="Editar usuário" subtitle="Edição usuário" />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Nome"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.name}
                name="name"
                error={!!touched.name && !!errors.name}
                helperText={(touched.name && typeof errors.name === 'string') ? errors.name : ''}
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Email"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.email}
                name="email"
                error={!!touched.email && !!errors.email}
                helperText={(touched.email && typeof errors.email === 'string') ? errors.email : ''}
                sx={{ gridColumn: "span 4" }}
                disabled
              />
          
              <TextField
                fullWidth
                variant="filled"
                type="password"
                label="Senha"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.senha1}
                name="senha1"
                error={!!touched.senha1 && !!errors.senha1}
                helperText={(touched.senha1 && typeof errors.senha1 === 'string') ? errors.senha1 : ''}

                sx={{ gridColumn: "span 4" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="password"
                label="Repita a senha"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.senha2}
                name="senha2"
                error={!!touched.senha2 && !!errors.senha2}
                helperText={(touched.senha2 && typeof errors.senha2 === 'string') ? errors.senha2 : ''}

                sx={{ gridColumn: "span 4" }}
              />
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button type="submit" color="secondary" variant="contained">
                Atualizar dados
              </Button>
            </Box>
          </form>
        )}
      </Formik>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
};

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const checkoutSchema = yup.object().shape({
  name: yup.string().required("required"),
  email: yup.string().email("invalid email").required("required"),
  senha1: yup.string().required("required"),
  senha2: yup.string().required("required"),
});


export default Form;
