import React, { useEffect, useState } from "react";
import { NotasFaturamentoType } from "../../models/NotasFaturamento";
import LineChart from "../../components/LineChart";
import notasFaturamentoService from "../../services/notasFaturamentoService";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { useAuth } from '../../context/useAuth';


interface NotasFaturadasProps {
    dataInicial: Date | null;
    dataFinal: Date | null;
}



const NotasFaturadas: React.FC<NotasFaturadasProps> = ({ dataInicial, dataFinal }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const [items, setItems] = useState<NotasFaturamentoType[]>([]);
    const [filteredItems, setFilteredItems] = useState<NotasFaturamentoType[]>([]);
    const [chartData, setChartData] = useState<any[]>([]);
    const [totalSomado, setTotalSomado] = useState<number>();
    const { user } = useAuth();

    useEffect(() => {
        const carregarDadosNotasFaturamento = async () => {
            let notas = await notasFaturamentoService.getAllItens(user!);
            notas = notas.reverse();
            notas = filtrarDatas(notas);
            setItems(notas);
            setFilteredItems(notas);
        };
    
        carregarDadosNotasFaturamento();
    }, [dataInicial, dataFinal]); // Adicionar `dataInicial` e `dataFinal` como dependências
    

    useEffect(() => {
        if (filteredItems.length > 0) {
            prepareChartData(filteredItems);
            somarValorGrupos(filteredItems);
        }
    }, [filteredItems]); // Mudar a dependência para `filteredItems`
    

    const filtrarDatas = (notas: NotasFaturamentoType[]) => {
        if (dataInicial && dataFinal) {
            notas = notas.filter(item => {
                const dt_lancamento = new Date(item.dt_lancamento!);
                return dt_lancamento >= dataInicial && dt_lancamento <= dataFinal;
            });
        } else if (dataInicial) {
            notas = notas.filter(item => new Date(item.dt_lancamento!) >= dataInicial);
        } else if (dataFinal) {
            notas = notas.filter(item => new Date(item.dt_lancamento!) <= dataFinal);
        }

        return notas;


    }
    const prepareChartData = (data: NotasFaturamentoType[]) => {
        const groupedData = data.reduce((acc, item) => {
            // Extrair ano e mês de dt_lancamento
            const [year, month] = item.dt_lancamento!.split("-").map(Number);
            const ano_mes = year * 100 + month; // Converte para formato YYYYMM

            // Encontre ou crie o grupo apropriado
            let foundGroup = acc.find(group => group.id === item.subgrupo);
            if (!foundGroup) {
                foundGroup = { id: item.subgrupo || "", data: [] as { x: number, y: number }[] };
                acc.push(foundGroup);
            }

            // Encontre ou crie a entrada para o ano_mes específico
            let foundData = foundGroup.data.find(d => d.x === ano_mes);
            if (!foundData) {
                foundData = { x: ano_mes, y: 0 };
                foundGroup.data.push(foundData);
            }

            // Some o vl_total ao y existente
            foundData.y += Number(item.vl_total);

            return acc;
        }, [] as { id: string, data: { x: number, y: number }[] }[]);

        setChartData(groupedData);
    };

    const somarValorGrupos = (itensSomar:NotasFaturamentoType[]) => {

        const totalSomado = itensSomar.reduce((acc, item) => {
            return acc + Number(item.vl_total!)

        }, 0)

        setTotalSomado(totalSomado);
    }



    return <>
        <Box
            gridColumn={isMobile ? "span 12" : "span 8"}
            gridRow="span 2"
            sx={{ backgroundColor: colors.primary[400], marginBottom: "15px" }}
        >


            <Box
                sx={{ marginLeft: "3%", paddingTop: "1%" }}

            >

                <Typography
                    variant="h5"
                    fontWeight="600"
                    color={colors.grey[100]}
                >
                    Notas Faturadas Por Grupo
                </Typography>

                <Typography
                    variant="h3"
                    fontWeight="bold"
                    color={colors.greenAccent[500]}
                >
                    {/* $59,342.32 */}

                    {Number(totalSomado).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                </Typography>

            </Box>
            <Box height="250px" m="-20px 0 0 0">
                {/* <LineChart isDashboard={true} /> */}
                <LineChart isDashboard={true} data={chartData} />

            </Box>

        </Box>

    </>





}

export default NotasFaturadas;