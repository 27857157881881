import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { tokens } from "../theme";
import React, { ReactElement } from 'react';

interface CustomCardProps {
    title: string;
    subtitle: string;
    icon: ReactElement;
    variant_number?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "subtitle1" | "subtitle2" | "body1" | "body2" | "caption" | "overline"; // ou qualquer outro variant suportado pelo MUI
    variant_subtitle?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "subtitle1" | "subtitle2" | "body1" | "body2" | "caption" | "overline"; // ou qualquer outro variant suportado pelo MUI
}

const CustomCard: React.FC<CustomCardProps> = ({ title, subtitle, icon, variant_subtitle = "h4", variant_number="h4" }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <Box
            sx={{
                backgroundColor: colors.primary[400],
                padding: "20px",
                borderRadius: "8px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <Box display="flex" alignItems="center" mb={2}>
                {icon}
                <Typography variant={variant_number} sx={{ ml: 1, color: colors.greenAccent[500], fontWeight: 'bold' }}>
                {title}
                </Typography>
            </Box>
            <Typography variant={variant_subtitle} color="textSecondary">
                {subtitle}
            </Typography>
        </Box>
    );
};

export default CustomCard;
