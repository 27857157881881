import { ENDPOINTS } from "../configs/apiConfigs"; // Ajuste o caminho conforme necessário

// Função para registrar um novo usuário
const registerUser = async (nome: string, email: string, password: string) => {
  try {
    const response = await fetch(ENDPOINTS.users.register, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ nome, email, password }),
    });

    const data = await response.json();

    console.log("Resposta do registro");
    console.log(data);

    if (!response.ok) {
      throw new Error("Falha no registro");
    }

    // Armazene o token e outras informações necessárias, se aplicável
    // Por exemplo, localStorage.setItem('userToken', data.token);

    return { success: true, data };
  } catch (error) {
    console.error("Erro ao registrar:", error);
    return { success: false, error };
  }
};

// Função para atualizar informações do usuário por email
const updateUser = async (email: any, name: any, password: any) => {
  console.log(JSON.stringify({ name, email, password }));
  console.log(`${ENDPOINTS.users}/email/${email}`);
  try {
    const response = await fetch(`${ENDPOINTS.users.update}/email/${email}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${localStorage.getItem("token")}`, // Descomente se a API requer autenticação
      },
      body: JSON.stringify({ name, email, password, audience: "all" }),
    });

    const data = await response.json();

    console.log("Resposta da atualização do usuário");
    console.log(data);

    if (!response.ok) {
      throw new Error("Falha na atualização do usuário");
    }

    return { success: true, data };
  } catch (error) {
    console.error("Erro ao atualizar usuário:", error);
    return { success: false, error };
  }
};
// Função para atualizar informações do usuário por email
const updatePassword = async (email: any, name: any, password: any) => {
  console.log(JSON.stringify({ name, email, password }));
  // console.log(`${ENDPOINTS.users}/password/${email}`);
  console.log(`${ENDPOINTS.users.update}/password/${email}`);
  try {
    const response = await fetch(`${ENDPOINTS.users.update}/password/${email}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${localStorage.getItem("token")}`, // Descomente se a API requer autenticação
      },
      body: JSON.stringify({ name, email, password, audience: "all" }),
    });

    const data = await response.json();

    console.log("Resposta da atualização do usuário");
    console.log(data);

    if (!response.ok) {
      throw new Error("Falha na atualização do usuário");
    }

    return { success: true, data };
  } catch (error) {
    console.error("Erro ao atualizar usuário:", error);
    return { success: false, error };
  }
};

export { registerUser, updateUser, updatePassword };
