// src/components/StyledButton.tsx

import React from 'react';
import { Button, ButtonProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { tokens } from '../theme'; // Ajuste o caminho conforme necessário

// Definir os tipos para colorVariant
type ColorVariant = 'green' | 'red' | 'gray';

// Extender as propriedades do Button para incluir colorVariant
interface StyledButtonProps extends ButtonProps {
  colorVariant?: ColorVariant;
}

// Criar o StyledButton com tipagem correta
const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'colorVariant', // Evita que colorVariant seja passado para o DOM
})<StyledButtonProps>(({ theme, colorVariant = 'green' }) => {
  const colors = tokens(theme.palette.mode);
  let backgroundColor: string | undefined;
  let hoverBackgroundColor: string | undefined;
  let backgroundImage: string | undefined;
  let hoverBackgroundImage: string | undefined;
  let textColor = colors.grey[100];

  switch (colorVariant) {
    case 'green':
      backgroundColor = colors.greenAccent[500];
      hoverBackgroundColor = colors.greenAccent[600];
      textColor = "white"; // Ajustar a cor do texto para melhor legibilidade

      break;
    case 'red':
      backgroundColor = colors.redAccent[700];
      hoverBackgroundColor = colors.redAccent[800];
      textColor = "white"; // Ajustar a cor do texto para melhor legibilidade

      break;
    case 'gray':
      // Definindo um leve degradê da direita para a esquerda
      backgroundColor = 'transparent'; // Garantir que o backgroundColor não interfira no degradê
      backgroundImage = `linear-gradient(to left, ${colors.grey[300]}, ${colors.grey[500]})`;
      hoverBackgroundImage = `linear-gradient(to left, ${colors.grey[400]}, ${colors.grey[600]})`;
      textColor = colors.grey[900]; // Ajustar a cor do texto para melhor legibilidade
      break;
    default:
      backgroundColor = colors.greenAccent[400];
      hoverBackgroundColor = colors.greenAccent[500];
  }

  return {
    color: textColor,
    fontSize: '14px',
    fontWeight: 'bold',
    padding: '10px 20px',
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
    backgroundColor: backgroundColor,
    backgroundImage: backgroundImage,
    '&:hover': {
      backgroundColor: hoverBackgroundColor,
      backgroundImage: hoverBackgroundImage,
    },
  };
});

export default StyledButton;
