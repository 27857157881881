// src/components/Sidebar.tsx

import React, { useState } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../theme";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import BarChart from "@mui/icons-material/BarChart";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import Receipt from "@mui/icons-material/Receipt";
import Inventory from "@mui/icons-material/Inventory";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import AccountBox from "@mui/icons-material/AccountBox";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import AccountBalanceWallet from "@mui/icons-material/AccountBalanceWallet";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import PieChartOutlineOutlinedIcon from "@mui/icons-material/PieChartOutlineOutlined";
import TimelineOutlinedIcon from "@mui/icons-material/TimelineOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import { allowedRoles, allowedRolesUser } from "../../configs/apiConfigs";
import { useAuth } from '../../context/useAuth';
import EstoquePage from "../../pages/estoque";
import logo from '../../assets/logotipo.png'; // Importe a imagem do logotipo
import userImg from '../../assets/user.png'; // Importe a imagem do usuário

// Definindo uma interface para as props
interface ItemProps {
  title: string;
  to: string;
  icon: React.ReactNode;
  selected: string;
  setSelected: (selected: string) => void;
}

const Item: React.FC<ItemProps> = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.grey[100],
      }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

interface SidebarProps {
  isSidebar: boolean;
}

const Sidebar: React.FC<SidebarProps> = ({ isSidebar }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");

  const { user } = useAuth();

  function formatarPrimeiroNome(nomeCompleto: string): string {
    const partes = nomeCompleto.split(' ');
    if (partes.length > 0) {
      const primeiroNome = partes[0];
      return primeiroNome.charAt(0).toUpperCase() + primeiroNome.slice(1).toLowerCase();
    }
    return '';
  }

  return (
    <Box
      className={`sidebar ${isCollapsed ? 'collapsed' : 'expanded'}`}
      sx={{
        "& .pro-sidebar-inner": {
          background: `linear-gradient(180deg, #78C257 0%, #5E8E3E 100%) !important`, // Degradê verde
          height: "100%", // Garantir que a sidebar ocupe toda a altura do contêiner
          backgroundImage: `url(${logo})`, // Use a imagem importada
          backgroundPosition: 'top center', // Posiciona no topo e centralizado
          backgroundRepeat: 'no-repeat', // Impede a repetição da imagem
          backgroundSize: 'contain', // Ajusta o tamanho para caber adequadamente
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important",
          color: "#FFFFFF !important", // Deixando o texto em branco
        },
        "& .MuiTypography-root": {
          color: "#FFFFFF !important", // Deixando os textos dos títulos em branco
        },
        "& .pro-inner-item:hover": {
          color: `#FFD700 !important`, // Amarelo dourado no hover
        },
        "& .pro-menu-item.active": {
          backgroundColor: "rgba(0, 0, 0, 0.2) !important",
          "& .pro-inner-item": {
            color: "#FFD700 !important",
          },
        },
        "& .section-title": {
          backgroundColor: "#4C6A39", // Verde mais escuro
          color: "#FFFFFF", // Texto branco
          textAlign: "center", // Centraliza o texto
          margin: "15px 0 5px 0", // Margens para posicionamento adequado
          fontWeight: "bold", // Texto em negrito
        },
      }}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: "10px 0 20px 0",
              color: colors.grey[100],
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                <Typography variant="h3" color={colors.grey[100]}>
                  ADMIN
                </Typography>
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>

          {!isCollapsed && (
            <Box mb="25px">
              <Box display="flex" justifyContent="center" alignItems="center">
                <img
                  alt="profile-user"
                  width="100px"
                  height="100px"
                  src={userImg} // Use a imagem importada
                  style={{ cursor: "pointer", borderRadius: "50%" }}
                />
              </Box>
              <Box textAlign="center">
                <Typography
                  variant="h2"
                  color={colors.grey[100]}
                  fontWeight="bold"
                  sx={{ m: "10px 0 0 0" }}
                >
                  {formatarPrimeiroNome(user?.name || "")}
                </Typography>
                <Typography variant="h5" color={colors.greenAccent[500]}>
                  Seja bem vindo!
                </Typography>
              </Box>
            </Box>
          )}

          <Box paddingLeft={isCollapsed ? undefined : "10%"}>
            {allowedRoles.includes(user?.role ?? '') &&

              <Item
                title="Dashboard"
                to="/admin"
                icon={<HomeOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              />
            }
            {allowedRoles.includes(user?.role ?? '') &&
              <Typography
                variant="h6"
                color={colors.grey[300]}
                sx={{ m: "15px 0 5px 20px" }}
                className="section-title" // Aplicando a classe para personalizar
              >
                Gestão
              </Typography>
            }

            {/* Aqui só vai aparecer se estiver dentro dos roles permitidos */}
            {allowedRoles.includes(user?.role ?? '') &&
              <Item
                title="Notas Faturadas"
                to="/admin/notas_faturamento"
                icon={<Receipt />}
                selected={selected}
                setSelected={setSelected}
              />}
            {allowedRoles.includes(user?.role ?? '') &&
              <Item
                title="Cooperados"
                to="/admin/cooperados"
                icon={<PeopleOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              />}
            {allowedRoles.includes(user?.role ?? '') &&
              <Item
                title="Estoque"
                to="/admin/estoque"
                icon={<Inventory />}
                selected={selected}
                setSelected={setSelected}
              />}
            {allowedRoles.includes(user?.role ?? '') &&
              <Item
                title="Relatório Mês"
                to="/admin/notas_agrupadas_mes"
                icon={<BarChart />}
                selected={selected}
                setSelected={setSelected}
              />}

            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
              className="section-title" // Aplicando a classe para personalizar
            >
              Área do Cooperado
            </Typography>

            <Item
              title="Conta Movimento"
              to="/admin/conta_movimento_notas"
              icon={<AccountBalanceWallet />}
              selected={selected}
              setSelected={setSelected}
            />

            <Item
              title="Notas Cooperado"
              to="/admin/notas_emitidas"
              icon={<AccountBox />}
              selected={selected}
              setSelected={setSelected}
            />

            {allowedRolesUser.includes(user?.role ?? '') && (
              <>
                <Typography
                  variant="h6"
                  color={colors.grey[300]}
                  sx={{ m: "15px 0 5px 20px" }}
                  className="section-title" // Aplicando a classe para personalizar
                >
                  Configurações
                </Typography>

                <Item
                  title="Gestão Usuários"
                  to="/admin/usuarios"
                  icon={<PeopleOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
              </>
            )}

          </Box>
        </Menu>
      </ProSidebar>
    </Box >
  );
};

export default Sidebar;
