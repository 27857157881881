import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, TextField, useTheme, useMediaQuery } from "@mui/material";
import { DataGrid, GridColDef, GridRenderCellParams, GridToolbar, ptBR } from '@mui/x-data-grid';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Header from "../../components/Header";
import { tokens } from "../../theme";
import ContaMovimentoNotasService from "../../services/contaMovimentoNotasService"; // Ajuste o caminho conforme necessário
import React, { useEffect, useState } from "react";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import MonetizationOn from "@mui/icons-material/MonetizationOn";
import exportToExcel from "../../components/exportToExcel";
import { useAuth } from '../../context/useAuth';
import { allowedRoles } from "../../configs/apiConfigs";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ptBR as localePtBR } from "date-fns/locale";
import { Autocomplete, createFilterOptions } from '@mui/material';
import CustomCard from "../../components/CustomCard";
import AutoAwesomeMotion from "@mui/icons-material/AutoAwesomeMotion";
import CreditCardOff from "@mui/icons-material/CreditCardOff";
import CurrencyExchange from "@mui/icons-material/CurrencyExchange";
import { ContaMovimentoNotasType } from "../../models/ContaMovimentoNotas";
import Receipt from "@mui/icons-material/Receipt";
import { dataGridHeaderStyles } from "../../components/styles/dataGridStyles"; // Importe o objeto de estilos
import StyledButton from "../../components/StyledButton";

const ContaMovimentoNotas = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { user } = useAuth();
    const [items, setItems] = useState<ContaMovimentoNotasType[]>([]);
    const [capitalItens, setCapitalItens] = useState<ContaMovimentoNotasType[]>([]);
    const [icmsItens, setIcmsItens] = useState<ContaMovimentoNotasType[]>([]);
    const [filteredItems, setFilteredItems] = useState<ContaMovimentoNotasType[]>([]);
    const [filteredCapitalItens, setFilteredCapitalItens] = useState<ContaMovimentoNotasType[]>([]);
    const [filteredIcmsItens, setFilteredIcmsItens] = useState<ContaMovimentoNotasType[]>([]);
    const [itensSomados, setItensSomados] = useState<number>();
    const [subtitleText, setSubtitleText] = useState<string>();
    const [activeTab, setActiveTab] = useState<number>(0);
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const fetchItens = async () => {
        try {
            let itens: ContaMovimentoNotasType[] = [];
            if (allowedRoles.includes(user?.role ?? '')) {
                itens = await ContaMovimentoNotasService.getAllItens();
                // itens = await ContaMovimentoNotasService.getItemByCdEntidade(17);

            } else {
                // console.log("Trazer somente produção do login");
                const entidadeId = user?.cd_entidade ?? 0;
                itens = await ContaMovimentoNotasService.getItemByCdEntidade(entidadeId);


            }


            const notas = itens.filter(item => !(item.descricao || "").includes("Capital") && !(item.descricao || "").includes("ICMS"));
            const capital = itens.filter(item => (item.descricao || "").includes("Capital"));
            const icms = itens.filter(item => (item.descricao || "").includes("ICMS"));


            setItems(notas);
            setCapitalItens(capital); // Inicializa os itens filtrados
            setIcmsItens(icms); // Inicializa os itens filtrados

            setFilteredItems(notas); // Inicializa os itens filtrados
            setFilteredCapitalItens(capital); // Inicializa os itens filtrados
            setFilteredIcmsItens(icms); // Inicializa os itens filtrados

            calcularTotal();

        } catch (error) {
            console.log("Erro ao buscar itens: ", error);
        }
    };

    useEffect(() => {
        fetchItens();

    }, []);

    useEffect(() => {
        calcularTotal();
    }, [activeTab, filteredItems, filteredCapitalItens, filteredIcmsItens]);


    const handleFilter = () => {
        if (startDate && endDate) {

            if (activeTab === 0) {
                const filtered = items.filter(item => {
                    const itemDate = new Date(item.dt_lancamento!);
                    return itemDate >= startDate && itemDate <= endDate;
                });
                setFilteredItems(filtered);
            } else if (activeTab === 1) {
                const filtered = capitalItens.filter(item => {
                    const itemDate = new Date(item.dt_lancamento!);
                    return itemDate >= startDate && itemDate <= endDate;
                });
                setFilteredCapitalItens(filtered);

            } else if (activeTab === 2) {
                const filtered = icmsItens.filter(item => {
                    const itemDate = new Date(item.dt_lancamento!);
                    return itemDate >= startDate && itemDate <= endDate;
                });
                setFilteredIcmsItens(filtered);

            }
        }



    };

    const clearFilter = () => {
        setStartDate(null);
        setEndDate(null);
        setFilteredItems(items); // Resetando os itens filtrados para todos os itens
        setFilteredCapitalItens(capitalItens); // Resetando os itens filtrados para todos os itens
        setFilteredIcmsItens(icmsItens); // Resetando os itens filtrados para todos os itens
    };

    const calcularTotal = () => {
        let total = 0;

        if (activeTab === 0) {
            total = filteredItems.reduce((acc, item) => {
                const valor = item.classificacao === 'Debito' ? -item.valor! : item.valor;
                return acc + valor!;
            }, 0);


        } else if (activeTab === 1) {
            total = filteredCapitalItens.reduce((acc, item) => {
                const valor = item.classificacao === 'Debito' ? -item.valor! : item.valor;
                return acc + valor!;
            }, 0);


        } else if (activeTab === 2) {
            total = filteredIcmsItens.reduce((acc, item) => {
                const valor = item.classificacao === 'Debito' ? -item.valor! : item.valor;
                return acc + valor!;
            }, 0);

        }

        console.log("Total:", total, activeTab);
        setItensSomados(total);
        atualizarNomeSoma();
    }

    const atualizarNomeSoma = () => {

        if (activeTab === 0) {
            setSubtitleText("Valores a receber");
        } else if (activeTab === 1) {
            setSubtitleText("Valores de Capital");
        } else if (activeTab === 2) {
            setSubtitleText("Valores de ICMS");
        }
    }



    const columns: GridColDef[] = [
        { field: "numero_nf", headerName: "Nota (NF)", flex: 1, minWidth: 100 },
        {
            field: "dt_lancamento",
            headerName: "Dt Lançamento",
            flex: 1.5,
            minWidth: 150,
            renderCell: (params: GridRenderCellParams<string>) => (
                <Typography color={colors.greenAccent[500]}>
                    {new Intl.DateTimeFormat('pt-BR', { dateStyle: 'medium' }).format(new Date(params.row.dt_lancamento))}
                </Typography>
            ),
        },
        { field: "cd_lancamento", headerName: "Lançamento", flex: 1, minWidth: 100 },
        { field: "nomecliente", headerName: "Cliente", flex: 2, minWidth: 200 },
        {
            field: "valor",
            headerName: "Valor Total",
            flex: 1.3,
            minWidth: 150,
            renderCell: (params: GridRenderCellParams<number>) => (
                <Typography color={colors.greenAccent[500]}>
                    {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(params.row.valor)}
                </Typography>
            ),
        },
        {
            field: "classificacao",
            headerName: "Classificação",
            flex: 1,
            minWidth: 100,
            renderCell: (params: GridRenderCellParams<string>) => (
                <Box
                    sx={{
                        backgroundColor: params.value === 'Credito' ? 'green' : 'red',
                        color: 'white',
                        padding: '4px',
                        borderRadius: '4px',
                    }}
                >
                    {params.value}
                </Box>
            ),
        },
        { field: "descricao", headerName: "Descrição", flex: 1.5, minWidth: 150 },
    ];

    return (
        <Box m="20px">
            <Header title="Conta Movimento" subtitle="Movimento" />

            <Box
                mb="20px"
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: { xs: 'column', sm: 'row' },
                    alignItems: 'center',
                    gap: 2, // Espaçamento entre os botões
                }}
            >
                <Button
                    onClick={() => setActiveTab(0)}
                    sx={{
                        backgroundColor: activeTab === 0 ? colors.greenAccent[500] : colors.grey[300],
                        // color: activeTab === 0 ? colors.grey[100] : colors.greenAccent[300],
                        color: "white",
                        fontSize: "14px",
                        fontWeight: "bold",
                        padding: "10px 20px",
                        width: { xs: '100%', sm: 'auto' }, // Largura 100% em telas pequenas
                        mb: { xs: 2, sm: 0 }, // Margem inferior para telas pequenas
                    }}
                >
                    <Receipt sx={{ mr: "10px" }} />

                    Movimento Notas
                </Button>
                <Button
                    onClick={() => setActiveTab(1)}
                    sx={{
                        backgroundColor: activeTab === 1 ? colors.greenAccent[500] : colors.grey[300],
                        color: activeTab === 1 ? "white" : "white",
                        fontSize: "14px",
                        fontWeight: "bold",
                        padding: "10px 20px",
                        width: { xs: '100%', sm: 'auto' }, // Largura 100% em telas pequenas
                        mb: { xs: 2, sm: 0 }, // Margem inferior para telas pequenas
                    }}
                >
                    <MonetizationOn sx={{ mr: "10px" }} />

                    Movimento Capital
                </Button>
                <Button
                    onClick={() => setActiveTab(2)}
                    sx={{
                        backgroundColor: activeTab === 2 ? colors.greenAccent[500] : colors.grey[300],
                        // color: activeTab === 2 ? colors.grey[100] : colors.greenAccent[300],
                        color: "white",
                        fontSize: "14px",
                        fontWeight: "bold",
                        padding: "10px 20px",
                        width: { xs: '100%', sm: 'auto' }, // Largura 100% em telas pequenas
                    }}
                >
                    <CurrencyExchange sx={{ mr: "10px" }} />

                    Movimento ICMS
                </Button>
            </Box>

            <Box
                display="flex"
                alignItems="center"
                mb="20px"
                sx={{
                    flexDirection: { xs: 'column', sm: 'row' },
                    '& > *': {
                        mb: { xs: 2, sm: 0 }, // Adiciona espaço entre os itens em telas pequenas
                        mr: { sm: 2, xs: 0 }, // Adiciona margem à direita em telas maiores
                    },
                }}
            >


                <Box
                    display="flex"
                    alignItems="center"
                    mb="20px"
                    sx={{
                        flexDirection: { xs: 'column', sm: 'row' }, // Coluna em mobile, linha em telas maiores
                        '& > *': {
                            mb: { xs: 2, sm: 0 }, // Margem inferior em mobile
                            mr: { sm: 2, xs: 0 }, // Margem direita em telas maiores
                            width: { xs: '100%', sm: 'auto' }, // 100% width em mobile, auto em telas maiores
                        },
                    }}
                >
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={localePtBR}>
                        <DatePicker
                            label="Data Inicial"
                            value={startDate}
                            onChange={(newValue) => setStartDate(newValue)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    fullWidth={isMobile} // Ocupa 100% da largura em mobile
                                    sx={{
                                        mr: { sm: 2, xs: 0 }, // Margem direita em telas maiores
                                        width: { xs: '100%', sm: 'auto' }, // Ajuste de largura
                                    }}
                                />
                            )}
                        />
                        <DatePicker
                            label="Data Final"
                            value={endDate}
                            onChange={(newValue) => setEndDate(newValue)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    fullWidth={isMobile} // Ocupa 100% da largura em mobile
                                    sx={{
                                        mr: { sm: 2, xs: 0 }, // Margem direita em telas maiores
                                        width: { xs: '100%', sm: 'auto' }, // Ajuste de largura
                                    }}
                                />
                            )}
                        />
                    </LocalizationProvider>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleFilter}
                        sx={{
                            width: { xs: '100%', sm: 'auto' }, // 100% width em mobile, auto em telas maiores
                            mb: { xs: 2, sm: 0 }, // Margem inferior em mobile
                        }}
                    >
                        Filtrar
                    </Button>
                    <Button
                        variant="outlined"
                        color="secondary"
                        onClick={clearFilter}
                        sx={{
                            width: { xs: '100%', sm: 'auto' }, // 100% width em mobile, auto em telas maiores
                        }}
                    >
                        Limpar Filtros
                    </Button>
                </Box>


            </Box>

            <Box sx={{
                marginBottom: "15px",
            }} >




                <StyledButton
                    onClick={() => {
                        let itemsToExport;
                        let nome_relatorio = ""
                        if (activeTab === 0) {
                            itemsToExport = filteredItems;
                            nome_relatorio = "Conta Movimento Notas";
                        } else if (activeTab === 1) {
                            itemsToExport = filteredCapitalItens;
                            nome_relatorio = "Conta Movimento Capital";

                        } else if (activeTab === 2) {
                            itemsToExport = filteredIcmsItens;
                            nome_relatorio = "Conta Movimento ICMS";

                        }
                        if (!itemsToExport) {
                            itemsToExport = filteredItems;
                        }
                        exportToExcel(itemsToExport, nome_relatorio);
                    }} colorVariant="gray">
                    <DownloadOutlinedIcon sx={{ mr: "10px" }} />

                    Baixar em Excel
                </StyledButton>

            </Box>

            <Box
                display="grid"
                gridTemplateColumns="repeat(12, 1fr)"
                gridAutoRows="140px"
                gap="20px"
                marginBottom={"10px;"}
            >


                <Box
                    gridColumn={isMobile ? "span 6" : "span 4"}
                    sx={{ backgroundColor: colors.primary[400] }}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <CustomCard
                        title={Number(itensSomados).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}

                        subtitle={subtitleText || ""}
                        icon={
                            <AutoAwesomeMotion
                                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                            />
                        }
                    />
                </Box>
            </Box>
            <Box
                sx={{
                    ...dataGridHeaderStyles, // Aplica os estilos reutilizáveis
                    width: '100%',
                    height: '75vh',
                    overflowX: 'auto', // Adiciona rolagem horizontal
                }}
            >
                {activeTab === 0 && (
                    <DataGrid
                        componentsProps={{
                            toolbar: {
                                filter: {
                                    filterModel: {
                                        items: [],
                                        quickFilterValues: ['quick', 'filter'],
                                    },
                                },
                                printOptions: { disableToolbarButton: true },
                                csvOptions: { disableToolbarButton: true },
                                sx: {
                                    '& .MuiButton-root': {
                                        color: 'white',
                                        backgroundColor: colors.greenAccent[300],
                                        '&:hover': {
                                            backgroundColor: 'darkblue',
                                        },
                                        margin: "5px",
                                    },
                                },
                            },
                        }}
                        components={{
                            Toolbar: GridToolbar,
                        }}
                        localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                        rows={filteredItems}
                        columns={columns}
                    />
                )}
                {activeTab === 1 && (
                    <DataGrid
                        componentsProps={{
                            toolbar: {
                                filter: {
                                    filterModel: {
                                        items: [],
                                        quickFilterValues: ['quick', 'filter'],
                                    },
                                },
                                printOptions: { disableToolbarButton: true },
                                csvOptions: { disableToolbarButton: true },
                                sx: {
                                    '& .MuiButton-root': {
                                        color: 'white',
                                        backgroundColor: colors.greenAccent[300],
                                        '&:hover': {
                                            backgroundColor: 'darkblue',
                                        },
                                        margin: "5px",
                                    },
                                },
                            },
                        }}
                        components={{
                            Toolbar: GridToolbar,
                        }}
                        localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                        rows={filteredCapitalItens}
                        columns={columns}
                    />
                )}
                {activeTab === 2 && (
                    <DataGrid
                        componentsProps={{
                            toolbar: {
                                filter: {
                                    filterModel: {
                                        items: [],
                                        quickFilterValues: ['quick', 'filter'],
                                    },
                                },
                                printOptions: { disableToolbarButton: true },
                                csvOptions: { disableToolbarButton: true },
                                sx: {
                                    '& .MuiButton-root': {
                                        color: 'white',
                                        backgroundColor: colors.greenAccent[300],
                                        '&:hover': {
                                            backgroundColor: 'darkblue',
                                        },
                                        margin: "5px",
                                    },
                                },
                            },
                        }}
                        components={{
                            Toolbar: GridToolbar,
                        }}
                        localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                        rows={filteredIcmsItens}
                        columns={columns}
                    />
                )}

            </Box>

        </Box>
    );
};

export default ContaMovimentoNotas;
