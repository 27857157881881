// URL base da API
// const BASE_URL = 'http://localhost:4000/api/v1';
// const BASE_URL = 'http://localhost/coopoli_api/public/api';
const BASE_URL = 'https://coopoli.coop.br/backend/backend_laravel_copoli/public/api';

// Endpoints específicos como propriedades de um objeto
const ENDPOINTS = {
  authentication: {
    login: `${BASE_URL}/login`,
   
    // outros endpoints relacionados à autenticação podem ser adicionados aqui
  },
  users:{ 
    update:`${BASE_URL}/users`,
    register: `${BASE_URL}/users`,
    create_email: `${BASE_URL}/users/email`,
  },
  custos_desdobrados: `${BASE_URL}/custos_desdobrados`,
  notas_emitidas: `${BASE_URL}/notas_emitidas`,
  notas_emitidas_sem_centro: `${BASE_URL}/notas_sem_centro`,
  notas_agrupadas: `${BASE_URL}/notas_agrupadas_mes`,
  cooperados: `${BASE_URL}/cooperados`,
  estoque: `${BASE_URL}/estoque`,
  notas_faturamento: `${BASE_URL}/notas_faturamento`,
  conta_movimento_notas: `${BASE_URL}/conta_movimento_notas`,
  diretoria: `${BASE_URL}/diretorias`,
  
  // Adicione mais endpoints para diferentes funcionalidades conforme necessário
};

export { BASE_URL, ENDPOINTS };


// admin_master = Douglas 
// presidente = pode ver total da cooperativa
// diretor = pode ver somente do grupo dele [hortifruti e insumos, são somente 2 hoje.. (21/04/2024)] | pode haver mais grupos futuramente
// 

const allowedRoles:string[] = ['admin_master', 'presidente','diretor'];
const allowedRolesUser:string[] = ['admin_master', 'presidente'];
// ,'diretor','cooperado'
export {allowedRoles, allowedRolesUser}