import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { DataGrid, GridColDef, GridRenderCellParams, GridToolbar, GridToolbarQuickFilter, ptBR } from '@mui/x-data-grid';
import Header from "../../components/Header";
import NotasEmitidasService from "../../services/notasEmitidasService"; // Ajuste o caminho conforme necessário
import { NotasEmitidasTotais, NotasEmitidasType } from "../../models/NotasEmitidas";
import React, { useEffect, useState } from "react";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import ReceiptLong from "@mui/icons-material/ReceiptLong";
import exportToExcel from "../../components/exportToExcel";
import { useAuth } from '../../context/useAuth';
import { allowedRoles } from "../../configs/apiConfigs";
import { CustosDesdobradosType } from "../../models/CustosDesdobrados";
import custosDesdobrados from "../../services/custosDesdobrados";
import columnsCustos from "./columnsCustos";
import RodapeCustos from "./rodapeCustos";
import NotaSemCentroDialog from './NotaSemCentroDialog'; // Ajuste o caminho conforme necessário
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ptBR as localePtBR } from "date-fns/locale";
import { LocalizationProvider } from "@mui/x-date-pickers";
import CustomCard from "../../components/CustomCard";
import CurrencyExchange from "@mui/icons-material/CurrencyExchange";
import AutoAwesomeMotion from "@mui/icons-material/AutoAwesomeMotion";

import { dataGridHeaderStyles } from "../../components/styles/dataGridStyles"; // Importe o objeto de estilos
import StyledButton from "../../components/StyledButton";



const NotasEmitidas = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { user } = useAuth();
    const [items, setItems] = useState<NotasEmitidasType[]>([]);
    const [itemCustos, setItemCustos] = useState<CustosDesdobradosType[]>([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [openDialogNotaSemCentro, setOpenDialogNotaSemCentro] = useState(false);
    const [statusFilter, setStatusFilter] = useState<string | null>(null);
    const [startDateFilter, setStartDateFilter] = useState<Date | null>(null);
    const [endDateFilter, setEndDateFilter] = useState<Date | null>(null);
    const [allStatuses, setAllStatuses] = useState<string[]>([]);
    const [originalItems, setOriginalItems] = useState<NotasEmitidasType[]>([]);
    const [resumo, setResumo] = useState<NotasEmitidasTotais>({ totalValue: 0, itemCount: 0 });

    const fetchItens = async () => {
        try {
            let itens = null;
            if (allowedRoles.includes(user?.role ?? '')) {
                itens = await NotasEmitidasService.getAllItens();
            } else {
                const entidadeId = user?.cd_entidade ?? 0;
                itens = await NotasEmitidasService.getItemByEntidadeId(entidadeId);
            }

            if (itens) {
                itens = itens.map(item => ({
                    ...item,
                    nr_documento: Number(item.nr_documento) // Garantindo que nr_documento seja um número
                }));
                setItems(itens);
                setOriginalItems(itens); // Atualize a lista original

                // Obtenha os valores únicos de ds_status
                // const uniqueStatuses = getUniqueStatuses(itens);
                const uniqueStatuses = Array.from(new Set(itens.map(item => item.ds_status || '')));
                setAllStatuses(uniqueStatuses);
                const summaryInfo = calcularResumo(itens);
                setResumo(summaryInfo);

            }
        } catch (error) {
            console.log("Erro ao buscar itens desdobrados: ", error);
        }
    };

    useEffect(() => {

        fetchItens();
    }, [user?.role, user?.cd_entidade]);

    useEffect(() => {
        filterItems();
    }, [statusFilter, startDateFilter, endDateFilter]);

    const handleClearFilters = () => {
        setStatusFilter(null);
        setStartDateFilter(null);
        setEndDateFilter(null);
        setItems(originalItems); // Redefina para a lista original
    };



    const filterItems = () => {
        let filteredItems = originalItems;

        if (statusFilter) {
            filteredItems = filteredItems.filter(item => item.ds_status === statusFilter);
        }

        if (startDateFilter) {
            filteredItems = filteredItems.filter(item => new Date(item.dt_emissao || '') >= startDateFilter);
        }

        if (endDateFilter) {
            filteredItems = filteredItems.filter(item => new Date(item.dt_emissao || '') <= endDateFilter);
        }

        setItems(filteredItems);

        // Calcule o resumo
        const summaryInfo = calcularResumo(filteredItems);
        setResumo(summaryInfo);
    };





    const fetchCustosDesdobrados = async (nr_documento: number) => {
        try {
            let itens = null;
            // caso seja role admin_master ou gestor_mater, pode ter acesso a todos
            itens = await custosDesdobrados.getItemByNrDocumento(nr_documento);


            setItemCustos(itens)
        } catch (error) {
            console.log("Erro ao buscar itens desdobrados: ", error)
        }
    };



    const handleOpenDialog = (nota_emitida: NotasEmitidasType) => {
        // alert(nota_emitida.name);
        fetchCustosDesdobrados(nota_emitida?.nr_documento || 0);
        // setItemCustos(nota_emitida);
        setOpenDialog(true);
    };
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };


    const handleOpenDialogNotasSemCentro = () => {
        setOpenDialogNotaSemCentro(true);
    };

    const handleCloseDialogNotasSemCentro = () => {
        setOpenDialogNotaSemCentro(false);
    };
    const calcularResumo = (items: NotasEmitidasType[]): NotasEmitidasTotais => {
        const totalValue = items.reduce((sum, item) => {
            const value = Number(item.vl_total) || 0;
            return sum + value;
        }, 0);

        const itemCount = items.length;

        return { totalValue, itemCount };
    };


    const columns: GridColDef[] = [
        {
            field: "nr_documento",
            headerName: "Número Nota (NF)",
            flex: 1,
            minWidth: 120, // Define uma largura mínima
        },
        {
            field: "cd_entidade",
            headerName: "Cd Entidade",
            flex: 1,
            minWidth: 100, // Define uma largura mínima
        },
        {
            field: "name",
            headerName: "Nome",
            flex: 2,
            minWidth: 200, // Define uma largura mínima
        },
        {
            field: "cd_forma_pagamento",
            headerName: "Forma de pagamentos",
            flex: 1,
            minWidth: 150, // Define uma largura mínima
        },
        {
            field: "ds_status",
            headerName: "Status Nota",
            flex: 1,
            minWidth: 150,
            renderCell: (params: GridRenderCellParams<string>) => {
                let backgroundColor = 'transparent';
                let color = 'inherit';
                let fontWeight = 'normal';

                switch (params.value) {
                    case 'Cancelada':
                        backgroundColor = 'red';
                        color = 'white';
                        fontWeight = 'bold';
                        break;
                    case 'Faturada':
                        backgroundColor = 'green';
                        color = 'white';
                        fontWeight = 'bold';
                        break;
                    default:
                        backgroundColor = 'gray';
                        color = 'black';
                        fontWeight = 'bold';
                        break;
                }

                return (
                    <Box
                        sx={{
                            backgroundColor,
                            color,
                            fontWeight,
                            padding: '8px',
                            borderRadius: '4px',
                        }}
                    >
                        {params.value}
                    </Box>
                );
            }
        },
        {
            field: "dt_emissao",
            headerName: "Data do emissão",
            flex: 1,
            minWidth: 150, // Define uma largura mínima
            renderCell: (params: GridRenderCellParams<Date>) => (
                <Typography color={colors.greenAccent[500]}>
                    {new Intl.DateTimeFormat('pt-BR', {
                        dateStyle: 'medium'
                    }).format(new Date(params.row.dt_emissao))}
                </Typography>
            )
        },
        {
            field: "ds_obs",
            headerName: "Obervação",
            flex: 1,
            minWidth: 100, // Define uma largura mínima
        },
        {
            field: "vl_funrural",
            headerName: "FunRural/SENAR",
            flex: 1,
            minWidth: 100, // Define uma largura mínima
            renderCell: (params: GridRenderCellParams<number>) => {
                const isZero = params.row.vl_funrural === 0;
                return (
                    <Typography
                        sx={{
                            backgroundColor: isZero ? 'red' : 'transparent',
                            color: isZero ? 'white' : colors.greenAccent[500],
                            borderRadius: '4px',
                            padding: '8px',
                        }}
                    >
                        {new Intl.NumberFormat('pt-BR', {
                            style: 'currency',
                            currency: 'BRL',
                        }).format(params.row.vl_funrural)}
                    </Typography>
                );
            },
        },

        {
            field: "vl_total",
            headerName: "Valor Total",
            flex: 1,
            minWidth: 100, // Define uma largura mínima
            renderCell: (params: GridRenderCellParams<number>) => (
                <Typography color={colors.greenAccent[500]}>
                    {new Intl.NumberFormat('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                    }).format(params.row.vl_total)}
                </Typography>
            ),
        },
        {
            field: "btn_editar",
            headerName: "Editar",
            flex: 1,
            minWidth: 120, // Define uma largura mínima
            renderCell: ({ row }: { row: { access: string } }) => {
                return (
                    <Box
                        width="80%"
                        display="flex"
                        justifyContent="center"
                        sx={{
                            backgroundColor: colors.greenAccent[700],
                        }}
                        borderRadius="4px"
                    >
                        <Button onClick={() => handleOpenDialog(row as NotasEmitidasType)} sx={{ fontSize: "0.75em", color: "white" }}>Ver detalhes</Button>
                    </Box>
                );
            },
        },
    ];





    return (


        <Box m="20px">

            <Header title="Custos das Notas" subtitle="Lista de Custos" />
            <Box
                display="flex"
                alignItems="center"
                mb="20px"
                sx={{
                    flexDirection: { xs: 'column', sm: 'row' },
                    '& > *': {
                        mb: { xs: 2, sm: 0 }, // Adiciona espaço entre os itens em telas pequenas
                        mr: { sm: 2, xs: 0 }, // Adiciona margem à direita em telas maiores
                    },
                }}


            >

                <Autocomplete
                    options={allStatuses}
                    value={statusFilter ?? null}
                    onChange={(event, newValue) => setStatusFilter(newValue)}
                    isOptionEqualToValue={(option, value) => option === value}
                    filterOptions={createFilterOptions({
                        matchFrom: 'start',
                        stringify: option => option,
                    })}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Filtrar por Status"
                            variant="outlined"
                            sx={{ mr: { sm: 10, xs: 8 } }}
                        />
                    )}
                />
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={localePtBR}>

                    <DatePicker
                        label="Data Inicial"
                        value={startDateFilter}
                        onChange={(newDate) => setStartDateFilter(newDate)}
                        renderInput={(params) => <TextField {...params} variant="outlined" />}
                    // style={{ marginBottom: 16 }}
                    />

                    <DatePicker
                        label="Data Final"
                        value={endDateFilter}
                        onChange={(newDate) => setEndDateFilter(newDate)}
                        renderInput={(params) => <TextField {...params} variant="outlined" />}
                    // style={{ marginBottom: 16 }}
                    />
                </LocalizationProvider>




                <StyledButton
                    onClick={handleClearFilters}
                    colorVariant="green">

                    Limpar Filtros
                </StyledButton>

                <StyledButton
                    onClick={() => exportToExcel(items, "Custos Notas")}
                    colorVariant="gray">

                    <DownloadOutlinedIcon sx={{ mr: "10px" }} />
                    Baixar em Excel
                </StyledButton>


                {allowedRoles.includes(user?.role ?? '') && (
                    <Box marginLeft={"5px"}>


                        <StyledButton
                            onClick={handleOpenDialogNotasSemCentro}
                            colorVariant="green">

                            <ReceiptLong sx={{ mr: "10px" }} />
                            Notas Sem Centro
                        </StyledButton>

                        <NotaSemCentroDialog
                            open={openDialogNotaSemCentro}
                            onClose={handleCloseDialogNotasSemCentro}
                        />
                    </Box>
                )}


            </Box>



            <Box
                display="grid"
                gridTemplateColumns="repeat(6, 1fr)"
                gridAutoRows="140px"
                gap="10px"
            >

                <CustomCard
                    title={`${new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(resumo.totalValue)}`}
                    subtitle="Total Valor"
                    icon={
                        <CurrencyExchange
                            sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                        />
                    }
                />

                <CustomCard
                    title={`${resumo.itemCount}`}
                    subtitle="Total notas faturadas"
                    icon={
                        <AutoAwesomeMotion
                            sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                        />
                    }
                />

            </Box>

            <Box
                m="40px 0 0 0"
                height="100vh"
                sx={{
                    ...dataGridHeaderStyles, // Aplica os estilos reutilizáveis
                    width: '100%',
                    height: '75vh',
                    overflowX: 'auto', // Adiciona rolagem horizontal
                }}
            >

                <DataGrid

                    componentsProps={{
                        toolbar: {
                            filter: {
                                filterModel: {
                                    items: [],
                                    quickFilterValues: ['quick', 'filter'],
                                },
                            },
                            printOptions: { disableToolbarButton: true },
                            csvOptions: { disableToolbarButton: true },
                            sx: {
                                '& .MuiButton-root': { // Estiliza todos os botões na barra de ferramentas
                                    color: 'white', backgroundColor: colors.greenAccent[300], '&:hover': {
                                        backgroundColor: 'darkblue',
                                    },
                                    "m": "5px"
                                },
                            },
                        },

                    }}
                    components={{
                        Toolbar: GridToolbar,

                    }}
                    localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}

                    rows={items} columns={columns} />



            </Box>



            <Dialog open={openDialog} onClose={handleCloseDialog} fullWidth={true} fullScreen={true}>
                <DialogTitle sx={{ backgroundColor: colors.grey[600] }} >
                    Detalhes da nota
                </DialogTitle>



                <DialogContent sx={{ backgroundColor: colors.primary[400], color: colors.primary[300] }}>
                    <RodapeCustos rows={itemCustos} />

                    <DataGrid

                        componentsProps={{
                            toolbar: {
                                filter: {
                                    filterModel: {
                                        items: [],
                                        quickFilterValues: ['quick', 'filter'],
                                    },
                                },
                                printOptions: { disableToolbarButton: true },
                                csvOptions: { disableToolbarButton: true },
                                sx: {
                                    ...dataGridHeaderStyles, // Aplica os estilos reutilizáveis

                                    // '& .MuiButton-root': { // Estiliza todos os botões na barra de ferramentas
                                    //     color: 'white', backgroundColor: colors.grey[300], '&:hover': {
                                    //         backgroundColor: 'darkblue',
                                    //     },
                                    //     "m": "5px"
                                    // },
                                },
                            },

                        }}
                        components={{
                            Toolbar: GridToolbar,

                        }}
                        localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}

                        rows={itemCustos} columns={columnsCustos(colors)} />

                </DialogContent>
                <DialogActions sx={{ backgroundColor: colors.grey[600] }} >

                    <StyledButton
                        onClick={handleCloseDialog}
                        colorVariant="red">

                        Fechar
                    </StyledButton>

                    {/* <Button onClick={handleSaveChanges} sx={{ color: colors.greenAccent[200] }}>Salvar</Button> */}
                    {/* <Button type="submit" sx={{ color: colors.greenAccent[200] }}>Salvar</Button> */}
                </DialogActions>
            </Dialog>
        </Box>

    )



}


export default NotasEmitidas;