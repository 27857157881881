const textFieldStyles = {
    '& .MuiOutlinedInput-root': {
        // '& fieldset': {
        //     borderColor: 'green',
        // },
        // '&:hover fieldset': {
        //     borderColor: 'darkgreen',
        // },
        '&.Mui-focused fieldset': {
            borderColor: 'darkgreen',
        },
    },
    // '& .MuiInputLabel-root': {
    //     color: 'green',
    // },
    '& .MuiInputLabel-root.Mui-focused': {
        color: 'darkgreen',
    },
};

export default textFieldStyles
