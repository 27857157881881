import * as XLSX from 'xlsx';

const exportToExcel = (gridData: unknown[], fileName: string) => {
  const ws = XLSX.utils.json_to_sheet(gridData);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

  // Buffer
  XLSX.write(wb, { bookType: 'xlsx', type: 'buffer' });

  // Binary string
  XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

  // Download
  XLSX.writeFile(wb, `${fileName}.xlsx`);
};

export default exportToExcel