import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material";




const columnsCustos = (colors: any) => [
    {
        field: "nr_documento",
        headerName: "Número Nota (NF)",
        flex: 1,
        minWidth: 120, // Define uma largura mínima
    },
    {
        field: "cd_centro_custo",
        headerName: "Centro de Custo",
        flex: 1,
        minWidth: 150, // Define uma largura mínima
    },
    {
        field: "dt_lancamento",
        headerName: "Data do lançamento",
        flex: 1,
        minWidth: 150, // Define uma largura mínima
        renderCell: (params: GridRenderCellParams<Date>) => (
            <Typography color={colors.greenAccent[500]}>
                {new Intl.DateTimeFormat('pt-BR', {
                    dateStyle: 'medium'
                }).format(new Date(params.row.dt_lancamento))}
            </Typography>
        ),
    },
    {
        field: "nr_percentual",
        headerName: "Percentual",
        flex: 1,
        minWidth: 100, // Define uma largura mínima
    },
    {
        field: "vl_custo",
        headerName: "Valor Custo",
        flex: 1,
        minWidth: 150, // Define uma largura mínima
        renderCell: (params: GridRenderCellParams<number>) => (
            <Typography color={colors.greenAccent[500]}>
                {new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                }).format(params.row.vl_custo)}
            </Typography>
        ),
    },
    {
        field: "ds_centro_custo",
        headerName: "Centro de Custo",
        flex: 1,
        minWidth: 150, // Define uma largura mínima
    },
    {
        field: "grupo",
        headerName: "Grupo",
        flex: 1,
        minWidth: 120, // Define uma largura mínima
    },
    {
        field: "subgrupo",
        headerName: "SubGrupo (Produto)",
        flex: 1,
        minWidth: 150, // Define uma largura mínima
    },
];



  

export default columnsCustos;