import { Box, Button, TextField, Grid, InputAdornment } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/useAuth';
import React from "react";
import EmailIcon from '@mui/icons-material/Email';
import LockIcon from '@mui/icons-material/Lock';

interface loginFormProps {
  setIsAuthenticated: (isAuthenticaded: boolean) => void;
}

interface LoginValues {
  email: string;
  password: string;
}

const LoginForm: React.FC<loginFormProps> = ({ setIsAuthenticated }) => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const { loginUser } = useAuth();
  const navigate = useNavigate();

  const handleFormSubmit = async (values: LoginValues) => {
    loginUser(values.email, values.password);
  };

  return (
    <Grid container style={{ height: '100vh' }}>
      {/* Esquerda - Imagem de fundo */}
      <Grid
        item
        xs={12}
        md={6}
        style={{
          backgroundColor: '#3A5A40',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundImage: 'url("/tela_login.png")', // Defina a imagem de fundo
          backgroundSize: 'cover', // Garante que a imagem cubra todo o espaço
          backgroundPosition: 'center', // Centraliza a imagem
          backgroundRepeat: 'no-repeat', // Impede a repetição da imagem
        }}
      >
      </Grid>


      {/* Direita - Formulário de Login com fundo branco */}
      <Grid item xs={12} md={6} style={{
        backgroundColor: 'white',
        padding: '40px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative'
      }}>
        {/* Campo para imagem da logo transparente como marca d'água */}
        <Box
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            opacity: 0.1,
            zIndex: 1,
            backgroundImage: 'url("/sua-imagem-logo-transparente.png")',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center'
          }}
        />

        <Box width={isNonMobile ? '400px' : '100%'} style={{ zIndex: 2 }}>
          <h1 style={{ color: '#4F4F4F' }}>Seja Bem-Vindo</h1>
          <Formik
            onSubmit={handleFormSubmit}
            initialValues={{ email: '', password: '' }}
            validationSchema={yup.object().shape({
              email: yup.string().email("E-mail inválido").required("O e-mail é obrigatório"),
              password: yup.string().min(6, "A senha deve ter pelo menos 6 caracteres").required("A senha é obrigatória"),
            })}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit}>
                <TextField
                  fullWidth
                  variant="outlined"
                  type="email"
                  label="E-mail"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email}
                  name="email"
                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                  style={{ marginBottom: '20px' }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <EmailIcon style={{ color: '#4CAF50' }} />
                      </InputAdornment>
                    ),
                    style: { cursor: 'text' } // Aplica o cursor text ao input interno
                  }}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="password"
                  label="Senha"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.password}
                  name="password"
                  error={touched.password && Boolean(errors.password)}
                  helperText={touched.password && errors.password}
                  style={{ marginBottom: '20px' }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockIcon style={{ color: '#4CAF50' }} />
                      </InputAdornment>
                    ),
                    style: { cursor: 'text' } // Aplica o cursor text ao input interno
                  }}
                />

                <Button
                  type="submit"
                  fullWidth
                  color="primary"
                  variant="contained"
                  style={{
                    background: 'linear-gradient(90deg, #4CAF50, #8BC34A)', // Gradiente verde
                    color: 'white', // Cor do texto branco
                    borderRadius: '50px', // Borda arredondada
                    padding: '10px 20px', // Espaçamento interno
                    textTransform: 'none', // Remove transformação de texto para manter "LOGIN" em maiúsculas
                  }}
                >
                  LOGIN
                </Button>

                {/* <Box mt="10px" textAlign="center">
                  <a href="#" style={{ color: '#E74C3C' }}>Esqueci minha senha</a>
                </Box> */}
              </form>
            )}
          </Formik>
        </Box>
      </Grid>
    </Grid>
  );
};

export default LoginForm;
