import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Typography,
  useTheme,
  Card,
  CardContent,
  Grid,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridToolbar,
} from "@mui/x-data-grid";
import {
  AutoAwesomeMotion,
  AutoStoriesRounded,
  DownloadOutlined as DownloadOutlinedIcon,
} from "@mui/icons-material";
import Header from "../../components/Header";
import { tokens } from "../../theme";
import { useAuth } from '../../context/useAuth';
import { useNavigate } from 'react-router-dom';
import { allowedRoles } from '../../configs/apiConfigs';
import cooperadosService from '../../services/cooperadosService';
import CustomCard from '../../components/CustomCard';
import { CooperadosType } from '../../models/Cooperados';
import exportToExcel from '../../components/exportToExcel';
import { dataGridHeaderStyles } from "../../components/styles/dataGridStyles";
import StyledButton from '../../components/StyledButton';
import { ptBR } from '@mui/x-data-grid/locales';

// Importações para gráficos
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  CartesianGrid,
} from 'recharts';

interface SummaryInfo {
  itemCount: number;
  cooperadosByCity: Record<string, number>;
}

const Cooperados = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [cooperados, setCooperados] = useState<CooperadosType[]>([]);
  const [summary, setSummary] = useState<SummaryInfo>({ itemCount: 0, cooperadosByCity: {} });
  const [totalCapital, setTotalCapital] = useState<number>(0);
  const { user } = useAuth();

  useEffect(() => {
    const fetchCooperados = async () => {
      if (!allowedRoles.includes(user?.role ?? '')) {
        navigate('/admin');
      }
      try {
        const cooperados = await cooperadosService.getAllItens();
        setCooperados(cooperados);
        const resumo = calculateSummary(cooperados);
        setSummary(resumo);
        somarCapital(cooperados);
      } catch (error) {
        console.log("Erro ao buscar os usuários", error);
      }
    };
    fetchCooperados();
  }, [user, navigate]);

  const somarCapital = (items: CooperadosType[]) => {
    const total = items.reduce((acc, item) => acc + Number(item.total_capital || 0), 0);
    setTotalCapital(total);
  };

  const calculateSummary = (items: CooperadosType[]): SummaryInfo => {
    const itemCount = items.length;
    const cooperadosByCity = items.reduce<Record<string, number>>((acc, item) => {
      const city = item.ds_cidade;
      if (city) {
        acc[city] = (acc[city] || 0) + 1;
      }
      return acc;
    }, {});
    return { itemCount, cooperadosByCity };
  };

  const columns: GridColDef[] = [
    { field: "cd_entidade", headerName: "Código", minWidth: 100, type: "number", flex: 1 },
    { field: "nr_cpfcnpj", headerName: "CPF/CNPJ", minWidth: 150, flex: 1.2 },
    { field: "ds_entidade", headerName: "Nome", minWidth: 200, flex: 1.5 },
    {
      field: "total_capital",
      headerName: "Capital",
      minWidth: 150,
      flex: 1.2,
      renderCell: (params) => (
        <Typography color={colors.greenAccent[500]}>
          {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(params.value)}
        </Typography>
      ),
    },
    { field: "total_notas", headerName: "Qtde Notas", minWidth: 150, flex: 1 },
  ];

  // Preparar dados para o gráfico
  const chartData = Object.entries(summary.cooperadosByCity).map(([city, count]) => ({
    city,
    count,
  }));

  return (
    <Box m="20px">
      <Header title="Cooperados" subtitle="Gestão de Usuários" />

      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <CustomCard
            title={`${summary.itemCount}`}
            subtitle="Cooperados"
            icon={
              <AutoAwesomeMotion
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <CustomCard
            title={`${new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(totalCapital)}`}
            subtitle="Total Capital"
            icon={
              <AutoStoriesRounded
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <StyledButton
            onClick={() => exportToExcel(cooperados, "Cooperados")}
            colorVariant="gray"
            fullWidth
          >
            <DownloadOutlinedIcon sx={{ mr: "10px" }} />
            Baixar em Excel
          </StyledButton>
        </Grid>
      </Grid>

      <Box mt={4}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Card sx={{ height: '100%', padding: '16px', boxShadow: 3 }}>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Cooperados por Cidade
                </Typography>
                <List sx={{ maxHeight: 300, overflow: 'auto', color: "white" }}>
                  {Object.entries(summary.cooperadosByCity).map(([city, count]) => (
                    <ListItem
                      key={city}
                      sx={{
                        borderRadius: '4px',
                        mb: 1,
                        backgroundColor: colors.primary[100],
                        '&:hover': {
                          backgroundColor: colors.primary[200],
                        },
                      }}
                    >
                      <ListItemText
                        primaryTypographyProps={{ sx: { color: 'white' } }}
                        secondaryTypographyProps={{ sx: { color: 'white' } }}
                        primary={`${city}`}
                        secondary={`Cooperados: ${count}`}
                      />
                    </ListItem>
                  ))}
                </List>
              </CardContent>
            </Card>
          </Grid>

          {/* Gráfico Opcional */}
          <Grid item xs={12} md={6}>
            <Card sx={{ height: '100%', padding: '16px', boxShadow: 3 }}>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Cooperados por Cidade
                </Typography>
                <ResponsiveContainer width="100%" height={300}>
                  <BarChart data={chartData} margin={{ top: 20, right: 30, left: 0, bottom: 5 }}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="city" />
                    <YAxis />
                    <Tooltip />
                    <Bar dataKey="count" fill={colors.greenAccent[500]} />
                  </BarChart>
                </ResponsiveContainer>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>

      <Box
        mt={4}
        sx={{
          ...dataGridHeaderStyles,
          width: '100%',
          height: '600px',
          overflowX: 'auto',
        }}
      >
        <DataGrid
          componentsProps={{
            toolbar: {
              printOptions: { disableToolbarButton: true },
              csvOptions: { disableToolbarButton: true },
              sx: {
                '& .MuiButton-root': {
                  color: 'white',
                  backgroundColor: colors.greenAccent[300],
                  '&:hover': {
                    backgroundColor: colors.greenAccent[400],
                  },
                  margin: "5px",
                },
              },
            },
          }}
          localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
          getRowId={(row) => row.cd_entidade}
          rows={cooperados}
          columns={columns}
          components={{
            Toolbar: GridToolbar,
          }}
        />
      </Box>
    </Box>
  );
};

export default Cooperados;
