// import axios from "axios";
import { CustosDesdobradosType } from "../models/CustosDesdobrados";
import { ENDPOINTS } from "../configs/apiConfigs"; // Ajuste o caminho conforme necessário
import axiosInstance from './axiosConfig'; // Ajuste o caminho conforme necessário

const axios = axiosInstance;


class CustosDesdobradosService {
    private API_URL = ENDPOINTS.custos_desdobrados;

    // Método para obter todos os custos desdobrados
    public async getAllItens(): Promise<CustosDesdobradosType[]> {
        try {
            const response = await axios.get(this.API_URL);
            console.log(response.data);
            
            return response.data;
        } catch (error) {
            throw new Error(`Erro ao obter custos desdobrados: ${error}`);
        }
    }

    // Método para obter um custo desdobrado por ID
    public async getItemById(id: number): Promise<CustosDesdobradosType> {
        try {
            const response = await axios.get(`${this.API_URL}/${id}`);
            return response.data;
        } catch (error) {
            throw new Error(`Erro ao obter custo desdobrado: ${error}`);
        }
    }
    // Método para obter um custo desdobrado por ID
    public async getItemByEntidadeId(entidadeId: number): Promise<CustosDesdobradosType[]> {
        try {
            console.log(`${this.API_URL}/entidade/${entidadeId}`);
            
            const response = await axios.get(`${this.API_URL}/entidade/${entidadeId}`);
            return response.data;
        } catch (error) {
            throw new Error(`Erro ao obter custo desdobrado: ${error}`);
        }
    }
    // Método para obter um custo desdobrado por ID
    public async getItemByNrDocumento(nr_documento: number): Promise<CustosDesdobradosType[]> {
        try {
            console.log(`${this.API_URL}/nr_documento/${nr_documento}`);
            
            const response = await axios.get(`${this.API_URL}/nr_documento/${nr_documento}`);
            return response.data;
        } catch (error) {
            throw new Error(`Erro ao obter custo desdobrado: ${error}`);
        }
    }

    // Método para criar um novo custo desdobrado
    public async createItem(custo: CustosDesdobradosType): Promise<CustosDesdobradosType> {
        try {
            const response = await axios.post(this.API_URL, custo);
            return response.data;
        } catch (error) {
            throw new Error(`Erro ao criar custo desdobrado: ${error}`);
        }
    }

    // Método para atualizar um custo desdobrado
    public async updateItem(id: number, custo: CustosDesdobradosType): Promise<CustosDesdobradosType> {
        try {
            const response = await axios.put(`${this.API_URL}/${id}`, custo);
            return response.data;
        } catch (error) {
            throw new Error(`Erro ao atualizar custo desdobrado: ${error}`);
        }
    }

    // Método para deletar um custo desdobrado
    public async deleteItem(id: number): Promise<void> {
        try {
            await axios.delete(`${this.API_URL}/${id}`);
        } catch (error) {
            throw new Error(`Erro ao deletar custo desdobrado: ${error}`);
        }
    }
}

export default new CustosDesdobradosService();