
function somaColuna(rows: any[]) {
    return rows.reduce((acc, row) => acc + (row.vl_custo || 0), 0);
  }
  
  
interface RodapeCustosProps{
    rows: any[]
}
const RodapeCustos: React.FC<RodapeCustosProps> = ( {rows} ) => {
    const total = somaColuna(rows);
  
    return (
      <div style={{ padding: 16, textAlign: 'right', color:"white" }}>
        Total da nota: {new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL'
        }).format(total)}
      </div>
    );
  }

export default RodapeCustos;